import React, { useState } from 'react'
import { useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import { useAlert } from "react-alert";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import FullScreenLoading from 'react-fullscreen-loading';

const CustomLeaveGroup = () => {
   const alert = useAlert(); 
   
   
   
   const [leavegroup_name, setLeavegroup_name] = useState('')
    const [company_id, setCompany_id] = useState(Apiconnect.get_company_id())
    const [list, setList] = useState([])
    const [editId, setEditId] = useState(0)
    //templet
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [tempId, setTempId] = useState(false);
    const [tempName, setTempName] = useState(false);
    const [empList, setEmpList] = useState([]);
    //employe select
    const [allchecked, setallchecked] = useState(null);
    const [selectedEmp, setSelectedEmp] = useState([]);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);



    const handleSubmit = async (e) => {
        setFullScreenLoader(true)
        e.preventDefault()
        if(editId){
         const inf_a = {leavegroup_id: editId, leave_group_name:leavegroup_name}
         await Apiconnect.postData(`leave_manage/leave_group_update`, inf_a).then(res => {
            getAll()
            setFullScreenLoader(false)
         })
            setLeavegroup_name("")
            setEditId(0)
            alert.success("Leave Group updated")
            return  
        }
        const inf_b = {company_id , leavegroup_name};
        if(list.find(em => em.leavegroup_name.includes(inf_b.leavegroup_name))){
         alert.error("Group name already Exists")
         setLeavegroup_name("") 
        }else{
         !editId && await Apiconnect.postData(`leave_manage/leave_group_create`, inf_b).then(res => {getAll(); alert.success("New Group Added"); setFullScreenLoader(false)}).catch(err => console.log(err))
         setLeavegroup_name("") 
        }
        
    }

    const getAll = async () => {
        setFullScreenLoader(true)
        const inf_c = {company_id : Apiconnect.get_company_id()};
        await Apiconnect.postData(`leave_manage/leave_group_list`, inf_c).then(res => {setList(res.data.data); setFullScreenLoader(false)}) 
    }
    useEffect(() => {getAll()}, [])
   
    //update
    const handleUpdate = (id) => {
      const newEdit = list.find(item => item.leavegroup_pk_id === id)
      setLeavegroup_name(newEdit.leavegroup_name)
      setEditId(id)
    }
    //Delete
    const handleDelete = async (id) => {
      setFullScreenLoader(true)
      const inf_d = {leavegroup_id: id}
      await Apiconnect.postData(`leave_manage/leave_group_delete`, inf_d).then(res => {getAll(); alert.error("Group Deleted"); setFullScreenLoader(false)}).catch(err => console.log(err))
    }
    //Modal custom styles
    const customStyles = {
      content: {
         top: "15%",
         width: "60%",
         left: "21%",
         // marginTop: "50px",
         // top: '50%', width: '60%',
         // left: '50%',
         // right: 'auto',
         // bottom: 'auto',
         // marginRight: '-50%',
         // transform: 'translate(-50%, -50%)',
         // height:'80%',
      },
   };

    //Modal
    const applyTemplate = async (id,name) => { 
      setFullScreenLoader(true)
      
      const inf_e = {search: "", company_id: company_id}
      getEmployeeList_new(50,0,company_id,'','','','');
      // await Apiconnect.postData(`leave_manage/getemployeelist_new`, inf_e).then(res => {
      //    setFullScreenLoader(false)
      //    let _xtract = Apiconnect.decrypt_obj(res.data.data);
      //    setEmpList(_xtract.reverse())
      //    /* finding selected employee from the api */
      //    let ob = {};
      //   _xtract.map((val, key) => {
      //     if(val.lgemp_fk_group_id === id ){
      //       ob[val.id] = val.id;
      //     }else{
      //       ob[val.id] = '';
      //     }
      //     })
      //     setSelectedEmp(ob);                 
      // })
      
      setTempId(id);
      setTempName(name);
      setModalIsOpen(true);
    }
   // console.log(empList)
    //get all employee list
   //  const getempList = async (search) => {
      
   //  }
   //  useEffect(() => {getempList()}, [])
   //  console.log(empList)
    
   
   //get new employee list____SEARCH
    const getempList_new = async (search) => {
      setFullScreenLoader(true)
      const inf_e = {search: search, company_id: company_id}
      // await Apiconnect.postData(`leave_manage/getemployeelist_new`, inf_e).then(res => {
      //    let _xtract = Apiconnect.decrypt_obj(res.data.data);
      //    setEmpList(_xtract.reverse())
      //    setFullScreenLoader(false)
      // });
      getEmployeeList_new(50,0,company_id,search,'','','');
    }

    const getEmployeeList_new = (limit, offset,company_id, search, dept_id, empStatus, grp_id,emp_grp_id='') => {
      const data = {
        limit: 1000,
        offset: offset,
        search: search,
        company_id:company_id,
        dept_id:dept_id,
        empStatus:empStatus,
        group_id: grp_id,
        emp_grp_id:emp_grp_id,
      };
      setFullScreenLoader(true);
      Apiconnect.postData(`employee_management/get_employee_lists`, data).then( (response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setEmpList(_xtract.reverse());
         setFullScreenLoader(false);
      });
    };
   
    // input search
    const inputsearch = (e) => {
      let search = e.target.value;
      getempList_new(search);
    };

    //select all employee
    const sellectAll = (e) => {
      if (e.target.checked === true) {
        // console.log(e.target.checked);
        setallchecked("checked");
        let ob = {};
        empList.map((val, key) => {
          ob[val.id] = val.id;
        });
        setSelectedEmp(ob);
        // setSelectedCountEmp(ob);
      } else {
        setallchecked(null);
        let ob = {};
        empList.map((val, key) => {
          ob[val.id] = '';
        });
        setSelectedEmp(ob);
        // setSelectedCountEmp([]);
      }
    };

    const onInputChange2 = (e) => {
      setallchecked(null);
   
      if (e.target.checked === true) {
        setSelectedEmp({
          ...selectedEmp,
          [e.currentTarget.name]: e.currentTarget.value,
        });
      } else {
        setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: 0 });
   
      }
    };

    //Apply 
    const applyEmp = async (e) => {
      setFullScreenLoader(true)
      const inf_f = {company_id: company_id, selgroup_id: tempId, employee:selectedEmp}
      await Apiconnect.postData(`leave_manage/leave_group_emp_createupdate`, inf_f).then(res => {console.log(res.data.message); setFullScreenLoader(false)}).catch(err => console.log(err))
      setallchecked(selectedEmp);
   }

   /* ------------excel----- */

   function getSheetData(data, header) {
      var fields = Object.keys(data[0]);
      var sheetData = data.map(function (row) {
        return fields.map(function (fieldName) {
          return row[fieldName] ? row[fieldName] : "";
        });
      });
      sheetData.unshift(header);
      return sheetData;
    }
  
    const saveAsExcel = () => {
      let header = ["Id", "Name", "city_id", "city_name"]
      setFullScreenLoader(true)
  
      XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const sheetData = getSheetData(list, header);
        const totalColumns = sheetData[0].length;
  
        sheet1.cell("A1").value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        // sheet1.column("D").value("numberFormat")
        sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        range.style("border", true);
        return workbook.outputAsync().then((res) => {
          saveAs(res, "leavegrouplist.xlsx");
          setFullScreenLoader(false)
        });
      });
    }
   
   const [catList, setCategory] = useState([]);
    
   const [empStatus, setEmpStatus] = useState("");
   const [dept_id, setDept_id] = useState(null);
   const [grp_id, setGrp_id] = useState("")
   const [searchTerm, setSearchTerm] = useState('');
   const [grp, setGrp] = useState([]);
   const [empGroupList, setEmpGroup] = useState([]);

   useEffect(() => {getCategory()}, []);
   useEffect(() => {get_group_list()}, []);
   useEffect(() => {getEmpGroup()},[]);

   const getCategory = () => {
      var company_id = Apiconnect.get_company_id();
      setFullScreenLoader(true)
      Apiconnect.getData(`company_category/getall/${company_id}`).then(
        (response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setCategory(_xtract.reverse());
          setFullScreenLoader(false)
        }
      );
   };

   
   const get_group_list= async () => {
    const inf_c = {company_id : Apiconnect.get_company_id()};
    await Apiconnect.postData(`leave_manage/leave_group_list`, inf_c).then(res => setGrp(res.data.data)) 
   }  
   
   const onDeptChange = (e,env_val) => {
      // console.log(e.target.value);
      var company_id = Apiconnect.get_company_id();
      let dept_id = e.target.value;
      setDept_id(dept_id);
      getEmployeeList_new("50", "0",company_id, "", dept_id, '', '');
      // getEmpCount('',dept_id,empStatus)
   };

   const onGrpChange = (e) => {
      var company_id = Apiconnect.get_company_id();
      let grp_id = e.target.value;
      setGrp_id(grp_id);
      getEmployeeList_new("50", "0",company_id, "", '', '', grp_id);
      // getEmpCount('',dept_id,empStatus)
   }


   const getEmpGroup = () => {
      Apiconnect.getData(`common/getallemploymenttypeById/${Apiconnect.get_company_id()}`).then((response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setEmpGroup(_xtract.reverse());
      });
   }

   const onInputChange = e => { 
      var company_id = Apiconnect.get_company_id();
      let emp_grp_id = e.target.value; 
      getEmployeeList_new("50", "0",company_id, "", '', '', '',emp_grp_id); 
   };

   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">
               {fullScreenLoader
               ? (
               <FullScreenLoading loading loaderColor="#3498db" />
               ) 
               : null
               }
                  
            <div>
            <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
               <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between model_head">
                     <div className="iq-header-title">
                        <h4 className="card-title ">Group: {tempName}</h4>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-md-3"> 
                        <label>Employment Group</label> 
                        <select className="form-control" required name="emp_group"  onChange={e => onInputChange(e)}>
                           <option value="">Select Group</option>
                           {
                              empGroupList.map((val, key) => {
                                 return (
                                    <option key={key} value={val.id}>{val.name}</option>
                                 )
                              })
                           }
                        </select>
                     </div>
                     <div className="col-md-3"> 
                        <label>Department</label>
                        <select className="form-control" 
                           required
                           name="dept_id"
                           //value={dept_id}
                           onChange={(e) => onDeptChange(e)}
                        >
                           <option value="" disabled selected>Department</option>
                           {catList.map((val, key) => {
                              return (
                              <option key={key} value={val.id}>
                                 {val.name}
                              </option>
                              );
                           })}
                        </select> 
                     </div>
                     <div className="col-md-3">  
                        <label>Group</label>
                        <select className="form-control" name="grp" value={grp_id} onChange={e => onGrpChange(e)}>
                          <option value="" disabled selected>Group</option>
                          {grp.map((val, key) => {
                              return(
                                <option key={key} value={val.leavegroup_pk_id}>{val.leavegroup_name}</option>
                              )
                            })}
                        </select> 
                     </div>
                  </div>

                  <div className="iq-card-body">
                     <div className="row">
                        <div className='row' style={{display:'block',width:'100%', padding:'10px'}}>
                        <button className="btn btn-info" style={{marginLeft:'20px',marginTop: '-10px'}} onClick={e => applyEmp(e)}>Apply</button>
                           <div style={{marginTop: '-35px', textAlign: "right" }}>
                            <div>
                              <input
                                type="text"
                                autocomplete="off"
                                placeholder="Search here..."
                                name="search"
                                onChange={(e) => inputsearch(e)}
                              />
                            </div>
                          </div> 
                        </div>
                       
                     <table className="table table-striped table-hover">
                        <thead className="">
                           <tr>
                              <th scope="col"><input type="checkbox" name="select_all" checked={allchecked} onChange={(e) => sellectAll(e)}/></th>
                              {/* <th scope="col"></th> */}
                              <th scope="col">#</th>
                              <th scope="col" style={{ textAlign: "center" }}>Profile</th>
                              <th scope="col">Emp Code</th>
                              <th scope="col">Name</th>
                           </tr>
                        </thead>
                        <tbody className="">
                           {empList.map((val, key) => {
                              if (val.display_picture == null) {
                                 var profile_img = "/images/user/1.jpg";
                               } else {
                                 const min = 1;
                                 const max = 1000;
                                 var rand = min + Math.random() * (max - min);
                                 var profile_img =
                                   process.env.React_App_BASE_URL_API +
                                   "/uploads/thumbdp/" +
                                   val.display_picture +
                                   "?" +
                                   rand;
                               }
                               var ck = Object.keys(selectedEmp).find(function (element) {
                                 return selectedEmp[element] == val.id;
                               });  
                               var ck1 = "";
                               if (ck !== undefined) {
                                 ck1 = true;
                               }
                           return(
                              <tr key={val.id}>
                                 <th scope="row"><input type="checkbox" name={val.id} value={val.id} checked={ck1} onChange={(e) => onInputChange2(e)}/></th>
                                 <td>{key+1}</td>
                                 <td style={{ width: "130px", textAlign: "center" }}>
                                    <div className="" style={{ display: "grid" }}>
                                       <img src={profile_img} alt="profile-img" className="prof"/>
                                    </div>
                                 </td>
                                 <td>{val.emp_code}</td>
                                 <td>{val.first_name} {val.last_name}</td>
                              </tr>
                           )})}
                        </tbody>
                     </table>
                     </div>
                  </div>
                  <div className="text_center">
                     <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                  </div>
               </div>
            </Modal>
            </div>

                  <div className="row">
                     <div className="col-sm-12 col-lg-12">
                        <div className="iq-card">
                           <div className="iq-card-header d-flex justify-content-between">
                              <div className="iq-header-title">
                                 <h4 className="card-title">Custom Leave Group</h4>
                              </div>
                           </div>
                           <div className="iq-card-body">
                                 <form >
                                 <div className="row">
                                    <div className="col-md-4">
                                       <div className="form-group">
                                          <input className="form-control" type="text" placeholder='Group Name' name="leavegroup_name" autoComplete='off' value={leavegroup_name} style={{marginRight: '20px'}} onChange={e => setLeavegroup_name(e.target.value)}/>
                                       </div>
                                    </div>
                                    <div className='col-md-4'>
                                       <button className="btn badge badge-pill badge-success" onClick={handleSubmit} >{editId ? "Update" : "Add"}</button> 
                                    </div>
                                 </div>
                                 </form>
                                 <div className="row">
                                       <div className="table-responsive">
                                          <div className='row'>
                                             <div className='col-md-6'></div>
                                             <div className='col-md-6'>
                                                <button className='btn badge badge-pill mr-1 btn-warning float-right' onClick={() => saveAsExcel()}>Leave Group List Excel</button>
                                             </div>
                                          </div>
                                          <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                             <thead>
                                                <tr className="clr" >
                                                   <th>#</th>
                                                   <th>Title</th>
                                                   <th>Action</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                {list.map((val, key) => {

                                                   return (
                                                      <tr key={key}>
                                                         <th scope="row">{key + 1}</th>
                                                         <td>{val.leavegroup_name}</td>
                                                         <td>
                                                            <button style={{marginRight: 10}}  className="btn badge badge-pill badge-success" onClick={() => handleUpdate(val.leavegroup_pk_id)}>Edit</button>
                                                            <button style={{marginRight: 10}} className='btn badge badge-pill badge-danger' onClick={() => handleDelete(val.leavegroup_pk_id)}>delete</button>
                                                            <button onClick={() => {applyTemplate(val.leavegroup_pk_id, val.leavegroup_name)}} className="btn badge badge-pill btn-primary mr-1">Assign Employee</button>
                                                         </td>
                                                      </tr>
                                                   );
                                                })}
                                             </tbody>
                                          </table>
                                       </div>
                                 </div>
                           </div>
                        </div>
                     </div>
                  </div>
            </div>
         </div>
      </>
  )
}

export default CustomLeaveGroup