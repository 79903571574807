import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import "./admin.css";


const Formula_add = () => {
   const alert = useAlert();
   let history = useHistory();
   
   const localuser = JSON.parse(localStorage.getItem('user'));
   let user_id = Apiconnect.decrypt(localuser.id); 

   const [info, setInfo] = useState({
      title: "",
      description: "",
      head_id: '',

   });


   const { title, description, head_id } = info;

   const onInputChange = e => {
      setInfo({ ...info, [e.target.name]: e.target.value });
   };

   const onSubmit = async e => {
      e.preventDefault();
      const arr = info.head_id.split("_");
      info.head_typ = arr[0];
      info.head_id = arr[1];

      console.log('in add function.....');
     
      let info_enc = Apiconnect.encrypt_obj(info);
      const inf_a = { enc: info_enc,user_id:user_id,company_id:Apiconnect.get_company_id() };
      await Apiconnect.postData("formula/create", inf_a).then((response) => {
         setInfo({ title: '', description: '', head_id: '' });
         let last_id = response.data.id;
         inputList.push({formula_id:last_id});

         console.log('last_id',last_id);
         
         let info_enc1 = Apiconnect.encrypt_obj(inputList);
         const inf_a1 = { enc: info_enc1,user_id:user_id,company_id:Apiconnect.get_company_id() };
          Apiconnect.postData("formula/create_condition", inf_a1).then((response) => {
            setInputList([{ if_condition: "", f_operator: "",check_value:"",f_then:"",f_else:"" }]);
            alert.success(response.data.message);
            console.log(response.data);
         
            history.push("/formula_list"); 
         });

      });
    
   }
   useEffect(() => { getEarningHead(); getDeductionHead();}, []);
   const [earninghead, setEarningHead] = useState([]);
   const [deductionhead, setDeductionHead] = useState([]);
   const [headList, setFormulaHead] = useState([]);

   const getEarningHead = () => {
      Apiconnect.getData(`formula/getearningall/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setEarningHead(_xtract.reverse());
      });

   };
   const getDeductionHead = () => {
      Apiconnect.getData(`formula/getdeductionall/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setDeductionHead(_xtract.reverse());
      });
   };
   const [inputList, setInputList] = useState([{if_condition: "", f_operator: "",check_value:"",f_then:"",f_else:"" }]);

   const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
   };

   const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
   };
   const handleAddClick = () => {
      setInputList([...inputList, { if_condition: "", f_operator: "",check_value:"",f_then:"",f_else:"" }]);
   };

    
   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Add Formula</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">



                           <form onSubmit={e => onSubmit(e)}>

                              <div className="row">
                                 <div className="form-group col-md-4">
                                    <label >Formula Title:</label>
                                    <input type="text" required className="form-control" name="title" value={title} onChange={e => onInputChange(e)} />
                                 </div>

                                 <div className="form-group col-md-4">
                                    <label >Formula Description:</label>
                                    <input type="text" required className="form-control" name="description" value={description} onChange={e => onInputChange(e)} />
                                 </div>

                                 <div className="form-group col-md-4">
                                    <label >Seelct Head:</label>
                                    <select className="form-control" name="head_id" value={head_id} onChange={e => onInputChange(e)} required>
                                       <option value=''>Select </option>
                                       {
                                          earninghead.map((val, key) => {
                                             return (
                                                <option key={key} value={"earning_" + val.id}>{val.name}</option>
                                             )
                                          })
                                       }
                                       {
                                          deductionhead.map((val, key) => {
                                             return (
                                                <option key={key} value={"deduction_" + val.id}>{val.name}</option>
                                             )
                                          })
                                       }
                                    </select>
                                 </div>


                                 <div className="form-group innerbox  col-md-12">

                              
                                    <div className="App">
                                       {inputList.map((x, i) => {
                                          return (
                                             <div key={i} className="box">


                                                <div className="row" style={{ padding: '10px' }}>
                                                   <div className="form-group col-md-2">
                                                      <label >If (condition):</label>
                                                      <input type="text" className="form-control" name="if_condition" value={x.if_condition}
                                                         onChange={e => handleInputChange(e, i)} required />
                                                   </div>
                                                   <div className="form-group col-md-3">
                                                      <label >Operator</label>
                                                      <select className="form-control" name="f_operator" value={x.f_operator}
                                                         onChange={e => handleInputChange(e, i)} required>
                                                         <option vallue="">--Select Operator--</option>
                                                         <option value=">"> &gt; </option>
                                                         <option value="<">&lt;</option>
                                                         <option value=">=">&gt;=</option>
                                                         <option value="<=">&lt;=</option>
                                                         <option value="==">==</option>
                                                         <option value="between" selected="">Between</option>
                                                         <option value="!=">!=</option>
                                                      </select>

                                                   </div>
                                                   <div className="form-group col-md-2">
                                                      <label >Chk Value:</label>
                                                      <input type="text" className="form-control" name="check_value" value={x.check_value}
                                                         onChange={e => handleInputChange(e, i)} required/>
                                                   </div>
                                                   <div className="form-group col-md-2">
                                                      <label >(Then)Formula:</label>
                                                      <input type="text" className="form-control" name="f_then" value={x.f_then}
                                                         onChange={e => handleInputChange(e, i)} required/>
                                                   </div>
                                                   <div className="form-group col-md-2">
                                                      <label >(Else)Formula:</label>
                                                      <input type="text" className="form-control" name="f_else" value={x.f_else}
                                                         onChange={e => handleInputChange(e, i)}  />
                                                   </div>


                                                   <div className="form-group col-md-1">
                                                      {inputList.length !== 1 &&
                                                         <button className="mtop50 btn-warning"
                                                            onClick={() => handleRemoveClick(i)}><i className="fa fa-trash "></i></button>}
                                                   </div>
                                                </div>

                                                <div className="btn-box">
                                                   {inputList.length - 1 === i && <button className="mtop50 btn btn-info" onClick={handleAddClick}>Add New Rule</button>}
                                                </div>
                                             </div>
                                          );
                                       })}
                                    </div>

                                 </div>

                                 <div className="form-group  col-md-12">
                                    <button type="submit" className="btn btn-primary mr-2">Submit</button>

                                    <Link style={{float:'right'}} className="btn btn-secondary"  to="/formula_list" >Back to List</Link>
                                 
                                 </div>

                              </div>

                           </form>

                        </div>
                     </div>
                  </div>
               </div>


            </div>
         </div>

              


      </>
   )
}

export default Formula_add
