import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useAlert } from "react-alert";
import Apiconnect from "../services/Apiconnect.js";
import "../ems_component/pages/ems_pages.css";
import "../ems_component/pages/userstyle.css";
import "react-datepicker/dist/react-datepicker.css";
import './public.css';
import Loadder from "./webview_lodder";
import Emplyee_leave_list_table from "../components/Emplyee_leave_list_table.component"
// import Axios from 'axios';
import { differenceInDays, format, formatDistance, formatRelative, parseISO, subDays, intervalToDuration } from 'date-fns'
import { confirmAlert } from 'react-confirm-alert'; // Import
import Axios from 'axios';

function Leave_details(props) {
  const alert = useAlert();

//   const localuser = JSON.parse(localStorage.getItem('user'));
//   let emp = Apiconnect.decrypt(localuser.id);
  let emp = props.match.params.id;
  let leave_id = props.match.params.leave_id;
  let company_id = props.match.params.company_id;


  const [loader, setLoader] = useState(false);

  const [creadited_leaves, setcreadited_leaves] = useState(0);
  const [debited_leaves, setdebited_leaves] = useState(0);
  const [carryforward_leaves, setcarryforward_leaves] = useState(0);
  const [leavestypelist, setleavestypelist] = useState([]);
  const [leaveList, setleaveList] = useState([]);
  const [is_refresh, setIs_refresh] = useState(Math.random());
  const [reportor, setReportorList] = useState({ first_name: '', last_name: '' });
  const { first_name, last_name } = reportor;
  const [data_user, setUser] = useState([]);


  useEffect(() => {
    getReportor();
    getLeaveByType();
    getLeaveList();
    loadUser();
  }, []);

  const loadUser = async () => { 
    Apiconnect.getData(`leave_manage/get_employee_by_id/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data); 
      setUser(_xtract); 
    }); 
  }; 

  const getLeaveList = () => {
    setLoader(true);
    Apiconnect.getData(`public/employeeleavedetails/${emp}/${leave_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data); 
      setleaveList(_xtract.reverse());
      setIs_refresh(Math.random());
       setLoader(false);
    });
  }


  const getReportor = () => {
    Apiconnect.getData(`public/leaveapprovalauthority/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setReportorList({ first_name: _xtract[0].first_name, last_name: _xtract[0].last_name });
    });
  }

  //////////////----------------Leave summary------------------///////////////////////

  const getLeaveByType = () => {
    Apiconnect.getData(`public/getAllLeaveByType/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setleavestypelist(_xtract.reverse());
    });

    Apiconnect.getData(`public/totalcreditedleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].creditedleaves !== null) {
        setcreadited_leaves(_xtract[0].creditedleaves);
      }
    });

    Apiconnect.getData(`public/totaltakenleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].debitleaves !== null) {
        setdebited_leaves(_xtract[0].debitleaves);
      }
    });

    Apiconnect.getData(`public/totalcarryforwardleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].carryforwardleaves !== null) {
        setcarryforward_leaves(_xtract[0].carryforwardleaves);
      }
    });

  }

 

  let reporting_officer=((typeof data_user[0]!="undefined")?data_user[0].reporting_officer:0);
  // console.log(reporting_officer);


  const leaveApprove = e => {
   
    confirmAlert({
       customUI: ({ onClose }) => {
         return (
          <div className='react-confirm-alert'>
             <div className='react-confirm-alert-body'>
                <h1>Are you sure?</h1>
                <p>You want to Approve this Leave?</p>
                <p><textarea name="mr_comments" style={{width:'100%'}} id="mr_comments" placeholder='Approve/Reject Note' ></textarea></p>
                <button class="btn badge badge-pill badge-danger" onClick={onClose}>Close</button>
                <button class="btn badge badge-pill badge-success" style={{marginLeft:20}}
                onClick={() => {
                 
                  

                   let approval_note = document.getElementById("mr_comments").value;
                  
                   let info = { id: e, status: 1, approved_by: reporting_officer, approval_note:approval_note };
                   let info_enc = Apiconnect.encrypt_obj(info);
                   const inf_a = { enc: info_enc }; 
                   Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
                      alert.success(response.data.message);
                      getLeaveList();
                      getLeaveByType();
                      setIs_refresh(Math.random());
                   });
                   onClose();
                }}
                >
                Yes, Approve Leave!
                </button>
             </div>
          </div>
         );
       }
     });

   

 }

 const leaveCancle = e => {

    confirmAlert({
       customUI: ({ onClose }) => {
         return (
          <div className='react-confirm-alert'>
             <div className='react-confirm-alert-body'>
                <h1>Are you sure?</h1>
                <p>You want to Reject this Leave?</p>
                <p><textarea name="mr_comments" style={{width:'100%'}} id="mr_comments" placeholder='Approve/Reject Note' ></textarea></p>
                <button class="btn badge badge-pill badge-danger" onClick={onClose}>Close</button>
                <button class="btn badge badge-pill badge-warning" style={{marginLeft:20}}
                onClick={() => {
                   let approval_note = document.getElementById("mr_comments").value; 
                   if(approval_note.trim() == ''){
                     alert.error('Please write reason to Reject leave');
                     return false;
                   }
                   let info = { id: e, status: 2, approved_by: reporting_officer, approval_note:approval_note };
                   let info_enc = Apiconnect.encrypt_obj(info);
                   const inf_a = { enc: info_enc };
                  Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
                      alert.success(response.data.message);
                      getLeaveList();
                      getLeaveByType();
                      setIs_refresh(Math.random());
                   });
                   onClose();
                }}
                >
                Yes, Reject Leave!
                </button>
             </div>
          </div>
         );
       }
     });

 }
 

  return (
    <>
  
      <div id="content-page" className="" style={{marginLeft:'unset !important',overflow: 'hidden',background: '#e2e2e2',padding: '20px 0px',minHeight: '100vh'}}>
        <div className="container-fluid leave_div">

        {
         loader ? <Loadder/>:
         <>
          <div className="row"  style={{fontSize:'13px'}}>
            
            <div className="col-md-6 col-sm-6">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Leave Details of {leaveList.length > 0 ? leaveList[0].name : ''}</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  {
                    leaveList.map((val, key) => {
                          var action1 = '';
                          if (val.status === 0) {
                             var status = <div className=" badge badge-warning">Pending</div>;
                             var action = <button className="btn badge badge-pill badge-success m-2" onClick={e => leaveApprove(val.id)}>Approve</button>;
                             var action1 = <button className="btn badge badge-pill badge-danger m-2" onClick={e => leaveCancle(val.id)}>Reject</button>;
                          }
                          if (val.status === 1) {
                             var status = <div className=" badge badge-success">Approved</div>;
                             var action = <button className="btn badge badge-pill badge-danger m-2" onClick={e => leaveCancle(val.id)}>Reject</button>;
                          }
                          if (val.status === 2) {
                             var status = <div className=" badge badge-danger">Rejected</div>;
                             var action = <button className="btn badge badge-pill badge-success m-2" onClick={e => leaveApprove(val.id)}>Approve</button>;
                          }

                          if (val.profile_pic == null) {
                            var profile_img = "/images/user/1.jpg";
                          } else {
                            const min = 1;
                            const max = 1000;
                            var rand = min + Math.random() * (max - min);
                            var profile_img =
                              process.env.React_App_BASE_URL_API +
                              "/uploads/thumbdp/" +
                              val.display_picture +
                              "?" +
                              rand;
                          }
                          
                          let start_date = format(new Date(val.leave_start_date), "dd-MM-yyyy")
                          let end_date = format(new Date(val.leave_end_date), "dd-MM-yyyy")

                          const dateone = new Date(val.leave_start_date);
                          const dateTwo = new Date(val.leave_end_date);
 
                          let count = ""
                          if(differenceInDays(dateone, dateTwo) == 0){
                            count = 1
                          }else{
                            count = (differenceInDays(dateTwo, dateone))
                          }
 

                      return(
                        <div className='row'>
                            <div className='col-md-12'>
                              <div className="d-flex justify-content-center">
                                  <div className="" style={{ display: "grid" }}>
                                      <img src={profile_img} alt="profile-img" className="prof"/>
                                  </div>
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <div className="d-flex justify-content-center">
                                <h5 className='text-info'>{val.name}</h5>
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <div className="d-flex justify-content-center">
                                <h6>{val.designation}</h6>
                              </div>
                            </div>
                            <div className='col-md-12'>
                            <div class="card text-center" style={{width: "70%"}}>
                              <div class="card-header bg-dark">{status}</div>
                                <div className='row d-flex justify-content-end'>
                                  <div className='col-md-4'></div>
                                  <div className='col-md-4'></div>
                                  <div className='col-md-4'></div>
                                </div>
                              <div class="card-body">
                                <h4 class="card-title">Applied For<br/><span className='text-info'>{val.leave_policy_name}</span><br/><h6><span className="badge bg-primary" style={{color: "white"}}>Total Days: {count}</span></h6></h4>
                                <h5 class="card-text">{val.applied_on}</h5>
                                <span class="card-text badge badge-secondary">From {val.leave_start_date1}</span>
                                <br/>
                                <span class="card-text badge badge-secondary">To {val.leave_end_date1}</span>
                                <p class="card-text"><span className='text-info'>Reason:</span> {val.reason}</p>
                                
                                <div className='row center d-flex justify-content-around'>
                                  {action}{action1}
                                </div>
                              </div>
                            </div>
                            </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="iq-card">
                
                <div className="iq-card-body">
                  <div className="row">

                    <div className="col-md-12" style={{textAlign: 'center',marginBottom:'20px'}}>
                      <Emplyee_leave_list_table
                          
                          sel_emp_id={emp} 
                          company_id={company_id} 
                          is_adm={0}
                          employee_gender={leaveList.length > 0 ? leaveList[0].gender : ''}
                          refresh={is_refresh}
                          
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themefourthcolor">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-calendar"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themefourthcolor">
                            {carryforward_leaves}</span>
                          <div className="databox-text darkgray">
                            Carry forward from last year</div>

                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themeprimary">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-user"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themeprimary">
                            {first_name} {last_name}
                          </span>
                          <div className="databox-text darkgray">
                            Approval Authority</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           



          </div>




         
          </>
          }
        </div>
      </div>

  
    </>
  )
}

export default Leave_details
