
import React, {useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import '../pages/pages.css';
import '../pages/admin.css';
import Pagination from "react-js-pagination";
import Loadder from "./Loadder";

// import 'bootstrap-less/bootstrap/bootstrap.less';



import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



const City = () => {

   const alert = useAlert();
   let history = useHistory();

   const localuser = JSON.parse(localStorage.getItem('user'));
   let user_typ = Apiconnect.decrypt(localuser.emp_typ);

   const [loader, setLoader] = useState(false);
   
   const [info, setInfo] = useState({
      name: "",
      state_id: "",
      id: null,
    });
    const { name, state_id,id } = info;
    const onInputChange = e => {
      setInfo({ ...info, [e.target.name]: e.target.value });
    };

    const onSubmit = async e => {
      e.preventDefault();

      if(id>0){
         console.log('in edit function' +id);
         let info_enc =  Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc };
         await Apiconnect.postData("city/update/",inf_a).then((response) => {
            console.log(response);
            setInfo({ name: '', state_id: '', id: ''});
            getCats();
            alert.success(response.data.message);   
         });
         
      }else{
         console.log('in add function.....' );
         let info_enc =  Apiconnect.encrypt_obj(info);
         // console.log(info_enc);
         const inf_a = { enc: info_enc };
         await Apiconnect.postData("city/create",inf_a).then((response) => {
            setInfo({ name: '', state_id: '', id: ''});
            getCats();
            alert.success(response.data.message);   
         });
      }     
    };


    const ondlt = (id) => {
      confirmAlert({
        title: 'Are you sure to Delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => deleteItem(id), 
          },
          {
            label: 'No',
          }
        ]
      });
    };

    const deleteItem = (id) => {
               Apiconnect.deleteData(`city/delete/${id}`).then((response) => {
                  getCats();
                  alert.success(response.data.message);      
               });
    };


    const getItem = (id) => {    
      Apiconnect.getData(`city/get/${id}`).then((response) => {        
         let _xtract = response.data.data;      
         setInfo({ name: _xtract[0].name, state_id: _xtract[0].state_id, id: _xtract[0].id });      
      });
       
    };

   
   useEffect(() => {getDeptCount('');getDept('50','1','');getState();}, []);
   const [catList, setCatList] = useState([]);
   const [stateList, setState] = useState([]);

   const getCats = () => {
      Apiconnect.getData(`city/getall`).then((response) => {
         let _xtract = response.data.data;
         setCatList(_xtract.reverse());   
           
      });
   };

   const getState = () => {
      Apiconnect.getData("state/getall").then((response) => {
          setState(response.data.data.reverse());
       });
     };


   function refreshPage(){ 
      window.location.reload(); 
   }


   const [viewList,setViewList] = useState({
      name1: "",
      state_id1: "",
   });

   const {  name1, state_id1} = viewList;

   const viewItem = (id)=>{
      setModalIsOpen(true);

      Apiconnect.getData(`city/get/${id}`).then((response) => {
         console.log(response.data.data[0]);
         let _xtract = response.data.data;
         setViewList({
            name1: _xtract[0].name, state_id1: _xtract[0].state_name});
      });
   }

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
        
      },
   };
   const [modalIsOpen, setModalIsOpen] = useState(false);

   const [activePage, setActivePage] = useState(1);
   const handlePageChange = (pageNumber) => {
      
      setActivePage(pageNumber);
      let offset = (pageNumber - 1) * 50 + 1;
      console.log(`active page is ${pageNumber} -- ${offset}`);
      let limit = 50;
      getDept(limit,offset,'');

    }


    const getDept = (limit,offset,search) => {
       setLoader(true);
      const data = {limit:limit,offset:offset,search:search};
     // Axios.post(`http://localhost:3050/api/city/getallcitylist`,data).then((response) => {
     Apiconnect.postData(`city/getallcitylist`,data).then((response) => {
         let _xtract = response.data.data;
         setCatList(_xtract.reverse());     
         setLoader(false);
      });
   };

   const [cityCount, setcityCount] = useState(0);
   const getDeptCount = (search) => {
       Apiconnect.getData(`city/getcitycount/${search}`).then((response) => {
         let _xtract = response.data.data;
         setcityCount(_xtract[0].cnt); 
      });
   };

   const inputsearch =  e => {
      let search = e.target.value;
      let offset = (activePage - 1) * 50 + 1;
      let limit = 50;

       getDept(limit,offset,search);
         // Axios.get(`http://localhost:3050/api/tush/getcitycount/${search}`).then((response) => {
              Apiconnect.getData(`city/getcitycount/${search}`).then((response) => {
                let _xtract = response.data.data;
                setcityCount(_xtract[0].cnt); 
                console.log(_xtract);
             });
   }


   return (
      <>
         <div id="content-page" className="content-page">
         <div className="container-fluid">
    
         <div >
                  <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">City View</h4>
                           </div>
                        </div>

                        <div className="iq-card-body">
                           <div className="row">
                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>City Name : </b></span>
                                    <span> {name1}</span>
                              </div>
                              </div>
                              
                              
                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>State : </b></span>
                                    <span>{state_id1}</span>
                                 </div>
                              </div>
                             
                           </div>
                        </div>
                        <div className="text_center">
                        <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div>


               <div className="row">
                  <div className="col-sm-12 col-lg-6">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">{id ? 'Area edit' : 'Area add'}</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                              <form onSubmit={e => onSubmit(e)}>
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="form-group">
                                    <label>City name <span className="required">*</span></label>
                                    <input type="text" className="form-control " required 
                                    name="name"
                                    value={name}
                                    onChange={e => onInputChange(e)} 
                                    placeholder="City Name"  />
                                    </div>
                                 </div>
                                 <div className="col-md-12">
                                 <div className="form-group">
                                    <label>State <span className="required">*</span></label>
                                    <select className="form-control" name="state_id" required
                                     value={state_id}
                                     onChange={e => onInputChange(e)}>
                                        <option value="">Select City</option>
                                        {stateList.map((val, key)=>{
                                           return(
                                           <option  key={key} value={val.id}>{val.name}</option>
                                           )
                                        })}
                                       
                                    </select>
                                 </div>
                              </div>

                                 <div className="col-md-12 mt-2">
                                    <button type="submit"  name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                                    {(() => {
                                       if (id>0) {
                                          return (
                                             <button type="button" className="btn" onClick={ refreshPage }> Return </button> 
                                          )
                                       } 
                                    })()}     
                                 </div>
                              </div>



                              </form>
                             

                        </div>
                     </div>
                  </div>

                   <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List of City</h4>
                             
                             
                           </div>
                           <div> Search <input type="text" name="search" onChange={e => inputsearch(e)}/></div>
                        </div>
                        <div className="iq-card-body">

                           <table className="table table-striped table-hover">
                                 <thead className="">
                                    <tr>
                                       <th scope="col">#</th>
                                       <th scope="col">Name</th>
                                       <th scope="col">State</th>
                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody className="">
                                    {
                                    loader ? <Loadder/>:
                                    catList.map((val, key) => {       
                                       return (
                                          <tr key={key}>  
                                             <th scope="row">{key+1}</th>
                                             <td>{val.name}</td>
                                             <td>{val.state_name}</td>
                                             <td>
                                             {
                                                user_typ === '3' 
                                                ? (
                                                   <button onClick={() => {ondlt(val.id);}}
                                                   className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>
      
                                                ) 
                                                : null
                                             }
                                             <button onClick={() => {getItem(val.id);}}
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                              
                                              <button onClick={() => viewItem(val.id)}
                                                className="btn btn-info mr-2"><i className="fa fa-eye" aria-hidden="true"></i></button>
                                            
                                             </td>
                                          </tr> 
                                       );
                                    })}                               
                                 </tbody>
                           </table>
                           <div>
                              <Pagination
                                 activePage={activePage}
                                 itemsCountPerPage={50}
                                 totalItemsCount={cityCount}
                                 pageRangeDisplayed={5}
                                 onChange={e => handlePageChange(e)}
                              />
                           </div>
                        </div>
                     </div>
                  </div>    

                 
               </div>




 
         </div>
         </div>
      </>
   )
}

export default City