import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../services/Apiconnect.js";
import "../ems_component/pages/ems_pages.css";
import "../ems_component/pages/userstyle.css";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Helper from "../services/HelperCodebase.js";
import './public.css';
import Loadder from "./webview_lodder";
import Combine_view_emp_leave_apply from "../components/combine_view_emp_leave_apply";

function Webview_leave(props) {
  const alert = useAlert();
  let history = useHistory();

    // console.log(props);
//   const localuser = JSON.parse(localStorage.getItem('user'));
//   let emp = Apiconnect.decrypt(localuser.id);
  let emp = props.match.params.id;
  let company_id = props.match.params.company_id;
  // let user_name=localuser.username;

  

  const [loader, setLoader] = useState(false);

  const [data_empinfo, setdata_empinfo] = useState('');

  const [info, setInfo] = useState({
    leave_typ: "", reason: "", company_id: company_id,
    id: null,
    emp_id: emp,
    typ: 'debit',
    status: 0,
    day_typ:''
  });
  const { leave_typ, reason, id,day_typ } = info;

  const [infoz, setInfoz] = useState({
    // creadited_leaves:0, 
    // debited_leaves:0,
    // carryforward_leaves:0,
    fromDate: new Date(),
    toDate: new Date(),
    mxDate: new Date(),
  });
  const { fromDate, toDate, mxDate } = infoz;

  const [creadited_leaves, setcreadited_leaves] = useState(0);
  const [debited_leaves, setdebited_leaves] = useState(0);
  const [carryforward_leaves, setcarryforward_leaves] = useState(0);
  const [leavestypelist, setleavestypelist] = useState([]);
  const [leaveTypeDay, setleaveTypeDay] = useState(true);

    
  const onInputChange = e => {
    setInfo({ ...info, [e.target.name]: e.target.value });

    if (e.target.name == 'leave_typ') {
      //console.log(e.target.name);
      //console.log(e.target.value);
      var typid = e.target.value;
      var this_typ = Helper.array_search_multidim('id', typid, List);

      var min_days = this_typ[0].leave_apply_days_min;
      var max_days = this_typ[0].leave_apply_days_max;

      var tx = parseInt(min_days) + parseInt(max_days);

      var myminDate = Helper.add_days(new Date(), min_days);
      var mymaxDate = Helper.add_days(new Date(), tx);
      setInfoz({
        ...infoz,
        fromDate: new Date(myminDate),
        toDate: new Date(myminDate),
        mxDate: new Date(mymaxDate),

      });




    }


  };
  const fromDateChage = async e => {
    var mymaxDate = Helper.add_days(e, 10);
    setInfoz({
      ...infoz,
      fromDate: e,
      toDate: e,
      mxDate: new Date(mymaxDate),
    });
  }
  const toDateChage = async e => {
    setInfoz({ ...infoz, toDate: e });
  }

  const [List, setleaveType] = useState([]);
  const [leaveList, setleaveList] = useState([]);
  const [reportor, setReportorList] = useState({ first_name: '', last_name: '' });
  const { first_name, last_name } = reportor;



  useEffect(() => {
    leaveType();
    getReportor();
    // getCreditedLeave();
    // getdebitedLeave();
    // getCarryforwardLeave();
    getLeaveByType();
    getLeaveList();
    getEmployeeData();
  }, []);





  const leaveType = () => {
    Apiconnect.getData(`public/company_leave_policy/${company_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setleaveType(_xtract.reverse());
    });


  }
  const getLeaveList = () => {
    setLoader(true);
    Apiconnect.getData(`public/employeeleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setleaveList(_xtract.reverse());
       setLoader(false);
    });
  }
  const getReportor = () => {
    Apiconnect.getData(`public/leaveapprovalauthority/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setReportorList({ first_name: _xtract[0].first_name, last_name: _xtract[0].last_name });
    });
  }

  //////////////----------------Leave summary------------------///////////////////////

  const getLeaveByType = () => {
    Apiconnect.getData(`public/getAllLeaveByType/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setleavestypelist(_xtract.reverse());
    });

    Apiconnect.getData(`public/totalcreditedleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].creditedleaves !== null) {
        setcreadited_leaves(_xtract[0].creditedleaves);
      }
    });

    Apiconnect.getData(`public/totaltakenleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].debitleaves !== null) {
        setdebited_leaves(_xtract[0].debitleaves);
      }
    });

    Apiconnect.getData(`public/totalcarryforwardleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].carryforwardleaves !== null) {
        setcarryforward_leaves(_xtract[0].carryforwardleaves);
      }
    });

  }

  const getEmployeeData= async () => {
  var data_post={
    company_id:company_id,
    employee_id:emp,
  }; 
  await Apiconnect.postData(`leave_manage/get_employee_details_by_id`,data_post).then((response) => { 
    setdata_empinfo((typeof response.data.data!='undefined')?Apiconnect.decrypt_obj(response.data.data):0);
  });
  }
  // console.log(data_empinfo);

  const onSubmit = async e => {
    e.preventDefault();

    let info='';
    await Apiconnect.getData(`public/getLeaveByTypeId/${emp}/${info.leave_typ}`,).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      let _xtract1 = Apiconnect.decrypt_obj(response.data.data1);
      let _xtract2 = Apiconnect.decrypt_obj(response.data.data2);

      if (_xtract[0].credit_cnt !== null) {
        var creadited_leaves1 = _xtract[0].credit_cnt;
      } else {
        var creadited_leaves1 = 0;
      }
      if (_xtract1[0].debit_cnt !== null) {
        var debited_leaves1 = _xtract1[0].debit_cnt;
      } else {
        var debited_leaves1 = 0;
      }
      if (_xtract2[0].carryforward_cnt !== null) {
        var carryforward_leaves1 = _xtract2[0].carryforward_cnt;
      } else {
        var carryforward_leaves1 = 0;
      }


      console.log('in add function');
      info.leave_start_date = format(fromDate, 'yyyy-MM-dd');
      info.leave_end_date = format(toDate, 'yyyy-MM-dd');

      var Difference_In_Time = toDate.getTime() - fromDate.getTime();
      var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
      var Final_Result = Difference_In_Days.toFixed(0);
      info.leave_count = Number(Final_Result) + 1;

      const available_leave = carryforward_leaves1 + creadited_leaves1 - debited_leaves1;
      const lop = Number(available_leave) - (Number(Final_Result) + 1);

      if (lop < 0) {
        info.lop = Math.abs(lop);
      } else {
        info.lop = 0;
      }

      let info_enc = Apiconnect.encrypt_obj(info);
      const inf_a = { enc: info_enc,user_id:emp };
      Apiconnect.postData("public/create_by_empid", inf_a).then((response) => {
        // console.log(response);
        history.push(`${emp}`);
        setInfo({
          leave_typ: "", reason: "", id: '', company_id: company_id,
          emp_id: emp, typ: 'debit',day_typ:''
        });
        alert.success(response.data.message);
        // console.log(response);
        getLeaveList();
        getLeaveByType();
      });

    })

  }

  const onDatepickerRef = el => { 
     if (el && el.input) { el.input.readOnly = true; }
   }

   const selectType = (type) => {
    if(type == 'full_day'){
      setleaveTypeDay(true)
    }
    if(type == 'half_day'){
      setleaveTypeDay(false)
    }
  }

  let employee_name_first=(typeof data_empinfo[0]!='undefined')?data_empinfo[0].first_name:'';
  let employee_name_last=(typeof data_empinfo[0]!='undefined')?data_empinfo[0].last_name:'';
 
  let employee_name1=employee_name_first+' '+employee_name_last;

  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
            <div className="row">

                {/*================strat_left_div==================*/}
                <div className="col-lg-12">
                    <Combine_view_emp_leave_apply
                        options={{ 
                          title: 'Welcome'
                        }}
                        sel_user_id={emp} 
                        company_id={company_id} 
                        sel_user_name={employee_name1}
                        alert={alert}
                        history={history}
                    />
                </div>
                {/*================end_left_div==================*/} 

            </div>
        </div>
    </div>

  
    </>
  )
}

export default Webview_leave
