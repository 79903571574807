import React, {useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import { Link } from 'react-router-dom'
import Modal from 'react-modal';
import Loadder from "./Loadder";

const Employee_salary_template_list = () => {

    const alert = useAlert();
    let history = useHistory();
    const locusr = JSON.parse(localStorage.getItem("user"));
    let user_typ = Apiconnect.decrypt(locusr.emp_typ);

    useEffect(() => {getTemplateList();},[]);
    const [List,setTemplateList]=useState([]);

    const getTemplateList = ()=> {
      Apiconnect.getData(`employee_salary_template/getAllTemplate/${Apiconnect.get_company_id()}`).then((response)=>{
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setTemplateList(_xtract.reverse());
      });
    }

    const deleteItem = (id) => {
      Apiconnect.deleteData(`employee_salary_template/delete/${id}`).then((response)=>{
        getTemplateList();
        alert.success(response.data.message); 
      });
 };

 const [modalIsOpen, setModalIsOpen] = useState(false);
 const [ListEmp, setEmployeeList] = useState([]);
 const [allchecked, setallchecked] = useState(null);
 const [selectedEmp, setSelectedEmp] = useState([]);
 const [loader, setLoader] = useState(false);
 const [tempId, setTempId] = useState(false);
 const [tempName, setTempName] = useState(false);

 const applyTemplate = (id,name) => {
   setLoader(true);
   const data = {
    search: '',
    company_id: Apiconnect.get_company_id(),
  };
    Apiconnect.postData(`employee/getemployeelist`,data).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        console.log('ListEmp',_xtract)
        setEmployeeList(_xtract.reverse());
        let ob = {};
        _xtract.map((val, key) => {
          if(val.salary_template_id === id ){
            ob[val.id] = val.id;
          }else{
            ob[val.id] = '';
          }
          })
          setSelectedEmp(ob);
        setLoader(false);
      }
    );
    setTempId(id);
    setTempName(name);
    setModalIsOpen(true);
 }

 const customStyles = {
   content: {
      top: '50%', width: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height:'80%',
   },
};


const onInputChange2 = (e) => {
   setallchecked(null);

   if (e.target.checked === true) {
     setSelectedEmp({
       ...selectedEmp,
       [e.currentTarget.name]: e.currentTarget.value,
     });
   } else {
     setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: 0 });

   }
 };

const sellectAll = (e) => {
   if (e.target.checked === true) {
     // console.log(e.target.checked);
     setallchecked("checked");
     let ob = {};
     ListEmp.map((val, key) => {
       ob[val.id] = val.id;
     });
     setSelectedEmp(ob);
     // setSelectedCountEmp(ob);
   } else {
     setallchecked(null);
     let ob = {};
     ListEmp.map((val, key) => {
       ob[val.id] = '';
     });
     setSelectedEmp(ob);
     // setSelectedCountEmp([]);
   }
 };

 const applyTemplateEmployee = async (e) => {

   const inf_a = { salary_template_id: tempId, employee: selectedEmp };
   await Apiconnect.postData("employee/salary_template_assign_update", inf_a).then(
     (response) => {
      alert.success("Salary Template Assign Successfully");
     }
   );
   
   setModalIsOpen(false);
   setSelectedEmp([]);
   setallchecked(null);
 }

 const inputsearch = (e) => {
  let search = e.target.value;
  getEmployeeList_new(search);
  
};

const getEmployeeList_new = (search) => {
  setLoader(true);
  const data = {
    search: search,
    company_id: Apiconnect.get_company_id(),
  };
  Apiconnect.postData(`employee/getemployeelist`, data).then(
    (response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmployeeList(_xtract.reverse());
      setLoader(false);
    }
  );
};

    return(
<>
<div id="content-page" className="content-page">
         <div className="container-fluid">

         <div>
            <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
               <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between model_head">
                     <div className="iq-header-title">
                        <h4 className="card-title ">Salary Template</h4>
                     </div>
                  </div>

                  <div className="iq-card-body">
                     <div className="row">
                        <div className='row'>
                           <div style={{margin:'5px 10px 10px 20px'}}><h4>Template : {tempName}</h4></div>
                        </div>

                        <div className='row' style={{display:'block',width:'100%', padding:'10px'}}>
                           <button onClick={(e)=>applyTemplateEmployee(e)} className="btn btn-info" style={{marginLeft:'20px',marginTop: '-10px'}}>Apply</button>
                           <div style={{marginTop: '-35px', textAlign: "right" }}>
                            <div>
                              <input
                                type="text"
                                placeholder="Search here..."
                                name="search"
                                onChange={(e) => inputsearch(e)}
                              />
                            </div>
                          </div> 
                        </div>
                       
                     <table className="table table-striped table-hover">
                    <thead className="">
                      <tr>
                        <th scope="col">
                          <input
                            type="checkbox"
                            name="select_all"
                            checked={allchecked}
                            onChange={(e) => sellectAll(e)}
                          />
                        </th>
                        <th scope="col">#</th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Profile
                        </th>
                        <th scope="col">Emp Code</th>
                        <th scope="col">Name</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {loader ? (
                        <Loadder />
                      ) : (
                        ListEmp.map((val, key) => {
                          if (val.display_picture == null) {
                            var profile_img = "/images/user/1.jpg";
                          } else {
                            const min = 1;
                            const max = 1000;
                            var rand = min + Math.random() * (max - min);
                            var profile_img =
                              process.env.React_App_BASE_URL_API +
                              "/uploads/thumbdp/" +
                              val.display_picture +
                              "?" +
                              rand;
                          }

                          var ck = Object.keys(selectedEmp).find(function (element) {
                            return selectedEmp[element] == val.id;
                          });

                          var ck1 = "";
                          if (ck !== undefined) {
                            ck1 = true;
                          }

                          return (
                            <tr key={key}>
                              <th scope="row">
                                <input
                                  type="checkbox"
                                  name={val.id}
                                  value={val.id}
                                  checked={ck1}
                                  onChange={(e) => onInputChange2(e)}
                                />
                              </th>
                              <th scope="row">{key + 1}</th>
                              <td
                                style={{ width: "130px", textAlign: "center" }}
                              >
                                <div className="" style={{ display: "grid" }}>
                                  <img
                                    src={profile_img}
                                    alt="profile-img"
                                    className="prof"
                                  />
                                </div>
                              </td>
                              <td>{val.emp_code}</td>
                              <td>
                                {val.first_name} {val.last_name}
                              </td>
                            </tr>
                          );
                        })
                      )}
                      </tbody>
                       </table>


                     </div>
                  </div>
                  <div className="text_center">
                     <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                  </div>
               </div>
            </Modal>
            </div>

               <div className="row">
                  <div className="col-sm-12 col-lg-12 col-md-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List Of Template</h4>
                           </div>
                           <Link style={{float:'right'}} className="btn btn-success"  to="/salary_template" >Add New</Link>
                        </div>
                        <div className="iq-card-body">
                           <table className="table table-striped table-hover">
                                 <thead className="">
                                    <tr>
                                       <th scope="col">#</th>
                                       <th scope="col">Name</th>
                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody className="">
                                    {
                                        List.map((val,key)=>{
                                            return(
                                                <tr key={key}>
                                                    <td style={{width:'100px'}}>{key+1}</td>
                                                    <td>{val.name}</td>
                                                    <td style={{width:'200px'}}>
                                                    {
                                                      user_typ === '3' 
                                                      ? (
                                                        <button onClick={() => {deleteItem(val.id);}} className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                                      ) 
                                                      : null
                                                    }
                                              <Link   to={`/salary_template_edit/${val.id}`} 
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                                             <button style={{marginTop:'5px'}} onClick={() => {applyTemplate(val.id,val.name)}} className="btn btn-primary mr-2">Apply Template</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                 </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
 
         </div>
         </div>
</>
    )
}

export default Employee_salary_template_list