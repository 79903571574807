import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from 'axios';
import ReactTooltip from 'react-tooltip';
import "./ems_pages.css";
import "./userstyle.css";
import moment from 'moment'

const Employee_compensatory_off = () => { 
    const customStyles = {
        content: {
           top: '50%', width: '75%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-37%, -50%)',  
           backgroundColor:"#E0E0E0",
           overflow:"scroll !important",
        },
    };

    const alert = useAlert();
    let history = useHistory(); 

    const localuser = JSON.parse(localStorage.getItem('user'));
    let user_id = Apiconnect.decrypt(localuser.id); 
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    let company_id=Apiconnect.get_company_id(); 
    const AuthStr = localuser.accessToken; 

    const [loader, setLoader] = useState(false); 
    const [list_employees, setlist_employees] = useState([]); 
    const [list_compensatoryoff, set_compensatoryoff] = useState([]); 
    const [modalIsOpen, setModalIsOpen] = useState(false); 
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    useEffect(() => {  
        getUserDetails(company_id);
        get_emp_compensatoryoff(company_id,user_id);
    }, []);

    
    const [data_info, setInfo] = useState({ 
        date_workedon:new Date(),
        sel_reason:'',
    });
    const [data_info_edit, setInfo_edit] = useState({sel_ecompoff_pk_id:''});
    const { date_workedon,sel_reason } = data_info;
    const { sel_ecompoff_pk_id } = data_info_edit;

    let data_ins1={company_id:company_id,employee_id:user_id,date_workedon:date_workedon,sel_reason:sel_reason};
   

    function popup_addedit(leavepolicy_id){
        setModalIsOpen(true);
    }

    const onInputChange = e => {
        setInfo({
            ...data_info,
            sel_reason: e.target.value, 
        });
    };
    const onInputChange_date = async (e) => { 
        setInfo({
            ...data_info,
            date_workedon: e, 
        });
    };
    
    const getUserDetails = async (company_id) => { 
        await Apiconnect.getData(`employee/getall/${company_id}`).then((response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setlist_employees(_xtract); 
        });  
    }

    const get_emp_compensatoryoff = async () => { 
        setLoader(true);
        await Apiconnect.postData(`employee_compensatoryoff/get_compensatory_off_lists`, data_ins1).then((response) => {
          setLoader(false);
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          set_compensatoryoff(_xtract); 
        });  
    } 

    const onSubmit = async e => {  
        e.preventDefault(); 
        setLoader(true); 
        
        if(sel_ecompoff_pk_id==''){
            getfunction_add();
        }else{
            getfunction_edit();
        }
    }

    const getfunction_add = async () => { 
        await Apiconnect.postData(`employee_compensatoryoff/compensatory_off_create`, data_ins1).then((response) => {
            setLoader(false);
            alert.success(response.data.message);
            setInfo({date_workedon:new Date(),sel_reason: ''});
            get_emp_compensatoryoff(); 
        });
    }
    const getfunction_edit= async () => { 
        data_ins1['sel_ecompoff_pk_id']=sel_ecompoff_pk_id;
        await Apiconnect.postData(`employee_compensatoryoff/compensatory_off_update`, data_ins1).then((response) => {
            setLoader(false);
            alert.success(response.data.message);
            setInfo({date_workedon:'',sel_reason: ''});
            setInfo_edit({sel_ecompoff_pk_id:''});
            get_emp_compensatoryoff(); 
        });
    }

    // console.log(localuser.fullname);
    // get_emp_compensatoryoff();
    const handleEdit = async (ecompoff_pk_id) => { 
        let data_edit1={employee_id:user_id,ecompoff_pk_id:ecompoff_pk_id}; 
        await Apiconnect.postData(`employee_compensatoryoff/get_employee_compensatory_off_byid`, data_edit1).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);  
            let ecompoff_date_workedon1=moment(_xtract[0].ecompoff_date_workedon).format("YYYY-MM-DD");
            setInfo({date_workedon:new Date(ecompoff_date_workedon1),sel_reason:_xtract[0].ecompoff_date_reason});  
            setInfo_edit({sel_ecompoff_pk_id:_xtract[0].ecompoff_pk_id}); 
        }); 
    }

    return (
        <>    
             
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <form onSubmit={(e) => onSubmit(e)}>
                        <div className="iq-card">  
                            <div className="iq-card-body">
                                <div className="row">
                                <div className="col-sm-12 col-lg-12" >
                                    <div className="col-sm-12 col-lg-12">
                                        <div className="row" style={{marginTop:10}}>

                                            <div className="form-group col-md-3">
                                                <label>Name</label>
                                                <input type='text' className='form-control' value={localuser.fullname} readOnly/>
                                            </div>  
                                            <div className="form-group col-md-3">
                                                <label>Worked On</label> 
                                                <DatePicker  
                                                    onChange={(e) => onInputChange_date(e)}
                                                    className="form-control"
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="Worked On"
                                                    name="date_workedon"
                                                    dropdownMode="select"
                                                    autoComplete="off"  
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={15}
                                                    scrollableYearDropdown  
                                                    style={{marginTop:20}}
                                                    selected={date_workedon}
                                                    required
                                                />
                                            </div>  
                                            <div className="form-group col-md-3">
                                                <label>Reason</label>
                                                <textarea 
                                                    className="form-control"
                                                    value={sel_reason}
                                                    onChange={(e) => onInputChange(e)}
                                                />
                                            </div>     

                                            <div className="form-group col-md-3">
                                                <button className="btn btn-success m-t-25" style={{marginTop:25}}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>    
                </div>
                 

                <div className="container-fluid">  
                    <div className="row m-t-10" style={{marginTop:10}}> 
                        <div className="col-sm-12 col-lg-12">
                        <div className="iq-card"> 
                            <div className="row"> 
                                <div className="col-sm-12 col-lg-12">&nbsp;</div>
                                <div className="col-sm-12 col-lg-12">

                                    <div className="col-sm-12 col-lg-12"> 
                                        <table className="table table-striped table-hover" >
                                            <thead className="" >
                                                <tr>
                                                    <th scope="col">#</th> 
                                                    <th scope="col">Date</th> 
                                                    <th scope="col">Reason</th> 
                                                    <th scope="col"> &nbsp;Status</th> 
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className=""> 
                                                {
                                                    list_compensatoryoff.length>0 ?(
                                                        list_compensatoryoff.map((val_compoff, key) => {
                                                            let action_1='';
                                                            let action_2=''; 
                                                            if (val_compoff.ecompoff_status_action === 0) {
                                                                action_1 = <div className=" badge badge-pill badge-warning">Pending</div>; 
                                                            }

                                                            return (
                                                                <tr key={key}>
                                                                    <td scope="row">{key + 1}</td>
                                                                    <td scope="row">{moment(new Date(val_compoff.ecompoff_date_workedon)).format("YYYY-MM-DD")}</td>
                                                                    <td scope="row">{val_compoff.ecompoff_date_reason}</td>
                                                                    <td scope="row">{action_1}</td>
                                                                    <td scope="row"><button style={{marginRight: 10}}  className="btn badge badge-pill badge-success" onClick={() => handleEdit(val_compoff.ecompoff_pk_id)} >Edit</button></td>
                                                                </tr>
                                                            )
                                                        })
                                                    ):
                                                    <tr>
                                                        <th colSpan={5} style={{textAlign:'center'}}>No Data Found..</th>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table> 
                                        
                                    </div> 
                                        
                                    
                                </div>
                                <div className="col-sm-12 col-lg-12">&nbsp;</div>
                            </div>
                        </div>
                        </div>
                    </div>

                </div>
            </div> 
        </>
    )
}

export default Employee_compensatory_off