import React, {useState, useEffect } from 'react';
import Axios from 'axios';
import {Link} from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js"; 

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Modal from 'react-modal';
import '../pages/pages.css';
import '../pages/admin.css';
import Pagination from "react-js-pagination";
import Loadder from "./Loadder";


const Leave_policy_manages = () => { 
    const alert = useAlert();
    let history = useHistory(); 

    const localuser = JSON.parse(localStorage.getItem('user'));
    let user_id = Apiconnect.decrypt(localuser.id); 
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    const AuthStr = localuser.accessToken; 
    useEffect(() => { 
                        getLeavePolicyList('50','0','');
                        getLeavePolicyCount("");
                    }, []);
    const [loader, setLoader] = useState(false);
 
    const customStyles = {
        content: {
           top: '40%', width: '75%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-37%, -50%)',  
           backgroundColor:"#E0E0E0",
           overflow:"scroll !important",
        },
    };
    const [modalIsOpen, setModalIsOpen] = useState(false);

    
    const [data_policy, setUser] = useState({
        leavepolicy_name1: "",
        company_id : Apiconnect.get_company_id(),
    });

    const {leavepolicy_name1} = data_policy;

    const onInputChange = e => {
        setUser({ ...data_policy, [e.target.name]: e.target.value ,company_id : Apiconnect.get_company_id()});
    };

    function popup_addedit(leavepolicy_id){
        setModalIsOpen(true);
    }

    const onSubmit = async e => {  
        e.preventDefault(); 
        setLoader(true);
        Apiconnect.postData("leave_manage/leave_policy_create", data_policy).then((response) => {  
           alert.success(response.data.message);   
           setLoader(false);
        });
        setUser({ leavepolicy_name1: ''});
        history.push("/leave_policy_create"); 
        getLeavePolicyList('','','');
    }

    const [List_leavepolicy, setleavepolicy] = useState([]);
    // const getLeavePolicyList = () => {
    //     Apiconnect.postData("leave_manage/leave_policy_list",data_policy).then((response) => { 
    //        let _xtract = response.data.data;
    //        setleavepolicy(_xtract.reverse()); 
    //     });
    // }

    const getLeavePolicyList = (limit, offset, search) => {
        setLoader(true);
        const data = {
          limit: limit,
          offset: offset,
          search: search,
          company_id: Apiconnect.get_company_id(), 
        };
        Apiconnect.postData(`leave_manage/leave_policy_list`, data).then( (response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data); 
            setleavepolicy(_xtract.reverse());
            setLoader(false);
        });
    };


    ////--------> Start Pagination And Search<--------///// 
    const [empCount, setempCount] = useState(0);
    const getLeavePolicyCount = (search) => { 
        const data = {
            search: search,
            company_id: Apiconnect.get_company_id(), 
        }; 

        Apiconnect.postData("leave_manage/get_leave_policy_search_count",data ).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setempCount(_xtract[0].cnt);
        });
    };
    const [activePage, setActivePage] = useState(1);
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        let offset = (pageNumber - 1) * 50; 
        let limit = 50;
        List_leavepolicy(limit, offset, "");
    };
    ////--------> Start Pagination And Search<--------/////

    return (
        <>
            {/* =============================================================== */}
            <div>
                <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                    <div className="iq-card cls_scrooly"> 
                        <div className="iq-card-body">
                            <form onSubmit={e => onSubmit(e)}>
                            <div className="row">
                                <div className="col-sm-12 col-lg-12"> 

                                    <div class="row">
                                        <div class="form-group col-md-4">
                                            <label>Name</label>
                                            <input 
                                                type="text" 
                                                autocomplete="off"
                                                className="form-control"
                                                name="leavepolicy_name1"
                                                onChange={e => onInputChange(e)}  
                                                placeholder="Name"
                                                required
                                                value={leavepolicy_name1}
                                            />
                                        </div> 
                                        <div class="form-group col-md-4" align="center">
                                            <button className="btn btn-success m-t-25">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </form>

                        </div>
                        <div className="text_center">
                            <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div>
                        <div className="col-sm-12 col-lg-12">&nbsp;</div>
                    </div>
                </Modal>
            </div>
            {/* =========================END_MODAL====================================== */}


            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    
                    <div className="row"> 
                        <div className="col-sm-12 col-lg-12">
                            <button type="button" className='btn btn-success pull-right'
                                    onClick={() => popup_addedit(0)}>
                                Add Policy
                            </button>
                        </div>
                    </div>

                    <div className="row m-t-10"> 
                        <div className="col-sm-12 col-lg-12">
                        <div className="iq-card">
                            <div className="row"> 
                                <div className="col-sm-12 col-lg-12">&nbsp;</div>
                                <div className="col-sm-12 col-lg-12">

                                    <div className="col-sm-12 col-lg-12"> 
                                        <table className="table table-striped table-hover">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">#</th> 
                                                    <th scope="col">Name</th> 
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className=""> 
                                            {
                                                loader ? <Loadder/>:
                                                List_leavepolicy.map((val_lgroup, key) => {       
                                                    return (
                                                        <tr key={key}>  
                                                            <th scope="row">{key+1}</th>
                                                            <td>{val_lgroup.leavepolicy_title}</td> 
                                                            <td> 
                                                                <Link to={`/leave_policy_manages_cat/${val_lgroup.leavepolicy_pk_id}`}
                                                                    className="btn btn-info"> 
                                                                    Manage Category
                                                                </Link>
                                                            </td> 
                                                        </tr> 
                                                    );
                                                })
                                            } 
                                            </tbody>
                                        </table> 
                                        <div className="">
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={50}
                                                totalItemsCount={empCount}
                                                pageRangeDisplayed={5}
                                                onChange={(e) => handlePageChange(e)}
                                            />
                                        </div>
                                    </div> 
                                     
                                    
                                </div>
                                <div className="col-sm-12 col-lg-12">&nbsp;</div>
                            </div>
                        </div>
                        </div>
                    </div>

                </div>
            </div> 
        </>
    )
}

export default Leave_policy_manages