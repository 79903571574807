import React, {useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import  "./pages.css";
import Apiconnect from "../../services/Apiconnect.js";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Weekly_off = () => {

  const alert = useAlert();
  let history = useHistory();
  const locusr = JSON.parse(localStorage.getItem("user"));
  let user_typ = Apiconnect.decrypt(locusr.emp_typ);
 
 useEffect(() => {getList();}, []);
  const [List, setList] = useState([]);

  const getList = () => {
    Apiconnect.getData(`company_weekly_off/getall/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setList(_xtract.reverse());    
     });
   };

  
   const week = [
     { id: "0", name: 'Sunday'}, 
     { id: "1", name: 'Monday'}, 
     { id: "2", name: 'Tuesday'}, 
     { id: "3", name: 'Wednesday'}, 
     { id: "4", name: 'Tuersday'}, 
     { id: "5", name: 'Friday'}, 
     { id: "6", name: 'Saturday'}, 
     ];

 
 
     const [info, setInfo] = useState({
      day_of_week: "",
      holiday_typ: "",
      company_id:Apiconnect.get_company_id(),
      id: null,
    });
    const { day_of_week, holiday_typ,company_id,id } = info;
    const onInputChange = e => {
      setInfo({ ...info, [e.target.name]: e.target.value });
    };

     const onSubmit = async e => {
      e.preventDefault();

       console.log(info);
      // return false;

      if(id>0){
         console.log('in edit function' +id);
         let info_enc =  Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc };
         await Apiconnect.postData("company_weekly_off/update/",inf_a).then((response) => {
            console.log(response);
            setInfo({ day_of_week: '', holiday_typ: '', id: '', company_id:Apiconnect.get_company_id()});
            alert.success(response.data.message);   
            getList();
         });
         
      }else{
         console.log('in add function.....' );
         let info_enc =  Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc };
         await Apiconnect.postData("company_weekly_off/create",inf_a).then((response) => {
            setInfo({ day_of_week: '', holiday_typ: '', id: '', company_id:Apiconnect.get_company_id()});
            alert.success(response.data.message);   
            getList();
         });
      }     
    };

    const ondlt = (id) => {
      confirmAlert({
        title: 'Are you sure to Delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => deleteItem(id), 
          },
          {
            label: 'No',
          }
        ]
      });
    };

    const deleteItem = (id) => {
      Apiconnect.deleteData(`company_weekly_off/delete/${id}`).then((response) => {
         getList();
         alert.success(response.data.message);      
      });
 };


 const getItem = (id) => {    
   Apiconnect.getData(`company_weekly_off/get/${id}`).then((response) => {        
      let _xtract = Apiconnect.decrypt_obj(response.data.data);      
      setInfo({ day_of_week: _xtract[0].day_of_week, holiday_typ: _xtract[0].holiday_typ,company_id: _xtract[0].company_id, id: _xtract[0].id });      
   });
    
 };


    return (
        <>

<div id="content-page" className="content-page">
         <div className="container-fluid">

               <div className="row">
                  <div className="col-sm-12 col-lg-12 col-md-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Weekly Off</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                         <form onSubmit={e => onSubmit(e)}>
                              <div   className="row "   >
                                  <div className="col-md-4 pt_15" > 
                                      <select required className="form-control" name="day_of_week" value={day_of_week}  onChange={e => onInputChange(e)}>
                                        <option>Select Day</option>
                                        {week.map((val, key) => { 
                                          return (
                                              <option value={val.id} >{val.name} </option>
                                            );
                                        })} 
                                      </select>
                                  </div>
                                  <div className="col-md-4 pt_15">
                                      <div className="form-group" >
                                        <select className="form-control" name="holiday_typ" value={holiday_typ}  onChange={e => onInputChange(e)} required>
                                          <option value="">Select Type</option>
                                          <option value="All">All</option>
                                          <option value="1">First</option>
                                          <option value="2">Second</option>
                                          <option value="3">Third</option>
                                          <option value="4">Fourth</option>
                                          <option value="5">Fifth</option>
                                        </select>
                                      </div>                                       
                                  </div>
                              </div>


                                 <div className="col-md-12 mt-2 ">
                                    <button type="submit"  name="id" value='Submit' className="btn btn-primary mr-2">Submit </button>
                                 </div>

                              </form>

                            
                        </div>
                     </div>
                  </div>
               
                  <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List of Weekly offs</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <table className="table table-striped table-hover">
                                 <thead className="">
                                    <tr>
                                       <th scope="col">#</th>
                                       <th scope="col">Day Of Week</th>
                                       <th scope="col">Type</th>
                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                
                                    {
                                 
                                    List.map((val,key)=>{
                                       if(List.length > 0){

                                        var name = '';
                                       if(week[val.day_of_week].id == val.day_of_week){
                                         var name = week[val.day_of_week].name;
                                       }

                                       const hol_type = [
                                          { id: "All", name: 'All'}, 
                                          { id: "1", name: 'First'}, 
                                          { id: "2", name: 'Second'}, 
                                          { id: "3", name: 'Third'}, 
                                          { id: "4", name: 'Fourth'}, 
                                          { id: "5", name: 'Fifth'},
                                    
                                         ]


                                       var holiday_type = '';
                                       var t = val.holiday_typ;
                                       if(val.holiday_typ == 'All'){
                                          var t = 0;
                                       }
                                       if(hol_type[t].id == val.holiday_typ){
                                         var holiday_type = hol_type[t].name;
                                       }

                                       return(
                                          <tr key={key}>
                                          <td scope="row">{key+1}</td>
                                          <td>{name}</td>
                                          <td>{holiday_type}</td>
                                          <td>
                                          {
                                             user_typ === '3' 
                                             ? (
                                                <button onClick={() => {ondlt(val.id);}}
                                                className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>
   
                                             ) 
                                             : null
                                          }
                                              {/* <button onClick={() => {deleteItem(val.id);}}
                                             className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button> */}

                                             <button onClick={() => {getItem(val.id);}}
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                          </td>
                                          </tr>
                                       );
                                       }
                                    })}
                                      
                                 </tbody>
                                  
                           </table>
                        </div>
                     </div>
                  </div>
               
               
                
               
               
               
               
               </div>

 
         </div>
         </div>



         
        </>
    )
}

export default Weekly_off


