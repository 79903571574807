import React, { Component }  from 'react'; 
import '../pages/admin.css';
 
import Apiconnect from "../../services/Apiconnect.js";
import { CSVLink } from "react-csv";
import { withAlert } from 'react-alert';
 


class Upload extends Component { 
    constructor(props) {
        super(props);
        this.state = { 
            employeeList: [] ,
            selectedFile:'', 
        
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.getEmployees(); 

       
    }

    handleInputChange(event) {
        this.setState({
            selectedFile: event.target.files[0],
          })
    }

     
    submit = async e => {
        const data = new FormData() ;
        data.append('csvfile', this.state.selectedFile);        
        Apiconnect.postData("test/uploadcsv", data).then((response) => {           
            this.props.alert.success(response.data.message)
            console.log(response.data.message);
        });
         
    };




        getEmployees = () => {  
            var company_id =  Apiconnect.get_company_id();       
            Apiconnect.getData(`employee/allempinfo/${company_id}`).then((response) => {
                let _xtract = Apiconnect.decrypt_obj(response.data.data);
                console.log('---...>>>>-----');
                console.log(_xtract);
                console.log('---...>>>>-----');
                this.setState({ employeeList: _xtract });            
            }); 
        }
        





    render() {
        

        return (
            <div id="content-page" className="content-page">
                        <div className="container-fluid">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Upload Employee Data</h4>                            
                                    </div>
                                </div>
                                <div className="iq-card-body">

                                <CSVLink filename={"employee-data.csv"} className="btn btn-primary" data={this.state.employeeList}  >
                                        Download me CSV
                                </CSVLink>
                                <br /> 
                                <br /> 
                                <br /> 
                                <br /> 

                                
                  <label className="text-white">Select File :</label>
                  <input type="file" id="file" className="form-control" name="csvfile" onChange={this.handleInputChange} />

                  <button type="submit" className="btn btn-dark" onClick={()=>this.submit()}>Save</button>
                                

                                <br /> 
                                <br /> 

 













                                </div>
                            </div>
                        </div>
            </div>

        










            









        )       
    }

}
export default withAlert()(Upload);