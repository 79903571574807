import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import '../pages/pages.css';
import '../pages/admin.css';


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const Company_category = () => {

   const alert = useAlert();
   let history = useHistory();
   const localuser = JSON.parse(localStorage.getItem('user'));
   let user_typ = Apiconnect.decrypt(localuser.emp_typ);

   const [info, setInfo] = useState({
      name: "",
      remark: "",
      company_id: Apiconnect.get_company_id(),
      id: null,
      emp_group:'',
   });
   const { name, remark, company_id, id, emp_group } = info;
   const onInputChange = e => {
      setInfo({ ...info, [e.target.name]: e.target.value });
   };

   const onSubmit = async e => {
      e.preventDefault();

      if (id > 0) {
         console.log('in edit function' + id);
         let info_enc = Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc };
         await Apiconnect.postData("company_category/update/", inf_a).then((response) => {
            console.log(response);
            setInfo({ name: '', remark: '', id: '', company_id: Apiconnect.get_company_id(),emp_group:'' });
            getCats();
            alert.success(response.data.message);
         });

      } else {
         console.log('in add function.....');
         let info_enc = Apiconnect.encrypt_obj(info);
         // console.log(info_enc);
         const inf_a = { enc: info_enc };
         await Apiconnect.postData("company_category/create", inf_a).then((response) => {
            setInfo({ name: '', remark: '', id: '', company_id: Apiconnect.get_company_id(),emp_group:'' });
            getCats();
            alert.success(response.data.message);
         });
      }
   };


   const ondlt = (id) => {
      confirmAlert({
         title: 'Are you sure to Delete?',
         buttons: [
            {
               label: 'Yes',
               onClick: () => deleteItem(id),
            },
            {
               label: 'No',
            }
         ]
      });
   };

   const deleteItem = (id) => {

      Apiconnect.getData(`company_category/employeedatabycompanycategory/${id}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         console.log(_xtract.length);
         if (_xtract.length > 0) {
            alert.success('Company Can\"t Delete');
         } else {
            Apiconnect.deleteData(`company_category/delete/${id}`).then((response) => {
               getCats();
               alert.success(response.data.message);
            });
         }

      });


      // Apiconnect.deleteData(`company_category/delete/${id}`).then((response) => {
      //    getCats();
      //    alert.success(response.data.message);      
      // });
   };


   const getItem = (id) => {
      Apiconnect.getData(`company_category/get/${id}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setInfo({ name: _xtract[0].name, remark: _xtract[0].remark, id: _xtract[0].id, emp_group:_xtract[0].emp_group_id });
      });

   };


   useEffect(() => { getCats();getEmpGroup(); }, []);
   const [catList, setCatList] = useState([]);
   const getCats = () => {
      Apiconnect.getData(`company_category/getall/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setCatList(_xtract.reverse());
      });
   };
   function refreshPage() {
      window.location.reload();
   }


   const [viewList, setViewList] = useState({
      name1: "",
      remark1: "",
      emp_group1:"",
   });

   const { name1, remark1 } = viewList;

   const viewItem = (id) => {
      setModalIsOpen(true);

      Apiconnect.getData(`company_category/get/${id}`).then((response) => {
         console.log(response.data.data[0]);
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setViewList({
            name1: _xtract[0].name, remark1: _xtract[0].remark, emp_group1:_xtract[0].emp_group_id
         });
      });
   }

   const [empGroupList, setEmpGroup] = useState([]);
   const getEmpGroup = () => {
       Apiconnect.getData(`common/getallemploymenttypeById/${Apiconnect.get_company_id()}`).then((response) => {
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           setEmpGroup(_xtract.reverse());
       });
   }

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',

      },
   };
   const [modalIsOpen, setModalIsOpen] = useState(false);

   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">

               <div >
                  <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Department View</h4>
                           </div>
                        </div>

                        <div className="iq-card-body">
                           <div className="row">
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <span><b>Name : </b></span>
                                    <span> {name1}</span>
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="form-group">
                                    <span><b>Remark : </b></span>
                                    <span>{remark1}</span>
                                 </div>
                              </div>

                           </div>
                        </div>
                        <div className="text_center">
                           <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div>


               <div className="row">
                  <div className="col-sm-12 col-lg-6">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">{id ? 'Department edit' : 'Department add'}</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <form onSubmit={e => onSubmit(e)}>
                              <div className="row">
                              <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Employment Group <span className="required">*</span></label>
                                            <select className="form-control" required name="emp_group" value={emp_group} onChange={e => onInputChange(e)}>
                                                <option value="">Select Group</option>
                                                {
                                                    empGroupList.map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.id}>{val.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                 <div className="col-md-12">
                                    <div className="form-group">
                                       <label>Department name <span className="required">*</span></label>
                                       <input type="text" className="form-control " required
                                          name="name"
                                          value={name}
                                          onChange={e => onInputChange(e)}
                                          placeholder="Department Name" />
                                    </div>
                                 </div>
                                 <div className="col-md-12">
                                    <div className="form-group">
                                       <label>Remarks</label>
                                       <input type="text" className="form-control " required
                                          name="remark"
                                          value={remark}
                                          onChange={e => onInputChange(e)}
                                          placeholder="Remarks" />
                                    </div>
                                 </div>
                                 <div className="col-md-12 mt-2">
                                    <button type="submit" name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                                    {(() => {
                                       if (id > 0) {
                                          return (
                                             <button type="button" className="btn" onClick={refreshPage}> Return </button>
                                          )
                                       }
                                    })()}
                                 </div>
                              </div>



                           </form>


                        </div>
                     </div>
                  </div>

                  <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List of Department</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <table className="table table-striped table-hover">
                              <thead className="">
                                 <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Employee Group</th>
                                    <th scope="col">Remark</th>
                                    <th scope="col">Action</th>
                                 </tr>
                              </thead>
                              <tbody className="">
                                 {catList.map((val, key) => {
                                    return (
                                       <tr key={key}>
                                          <th scope="row">{key + 1}</th>
                                          <td>{val.name}</td>
                                          <td>{val.employee_group}</td>
                                          <td>{val.remark}</td>
                                          <td>
                                          {
                                             user_typ === '3' 
                                             ? (
                                                <button onClick={() => { ondlt(val.id); }}
                                                className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>

                                             ) 
                                             : null
                                          }
                                          
                                             <button onClick={() => { getItem(val.id); }}
                                                className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button>

                                             {/* <button onClick={() => viewItem(val.id)}
                                                className="btn btn-info mr-2"><i className="fa fa-eye" aria-hidden="true"></i></button> */}


                                             {/* <Link to={`/leaveassign/${val.id}`}
                                                className="btn btn-secondary mr-2"><i className="fa fa-gear" aria-hidden="true"></i></Link> */}

                                             <div className="dropdown f_left">
                                                <span className="dropdown-toggle btn " id="dropdownMenuButton4" data-toggle="dropdown">
                                                   <i className="fa fa-cog"></i>
                                                </span>

                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton4">
                                                   <Link to={`/leaveassign/${val.id}`}
                                                      className="dropdown-item"><i className="ri-pencil-fill mr-2" aria-hidden="true"></i>Leaves Assign</Link>
                                                   <Link to={`/shiftassign/${val.id}`}
                                                      className="dropdown-item"><i className="ri-pencil-fill mr-2" aria-hidden="true"></i>Shift Assign</Link>
                                                </div>
                                             </div>

                                          </td>
                                       </tr>


                                    );
                                 })}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>


               </div>





            </div>
         </div>
      </>
   )
}

export default Company_category