import React, { useState } from 'react'
import { useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import { set } from 'date-fns';
import { useAlert } from "react-alert";
import {Link} from 'react-router-dom'
import LeavePolicyAssignEmp from './LeavePolicyAssignEmp.jsx';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import FullScreenLoading from 'react-fullscreen-loading';


const LeavePolicyList = (props) => {
    const alert = useAlert(); 
    
    const [list, setList] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [tempId, setTempId] = useState(false);
    const [tempName, setTempName] = useState(false);
    const [grpList, setGrpList] = useState([]);
    const [selectedGrp, setSelectedGrp] = useState([]);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
        /* -----------------Getting group policy list---------------- */
    const getPolicyList = async () => {
        const info_a = {search: "", company_id:Apiconnect.get_company_id(), limit: 50, offset: 0}
        await Apiconnect.postData("leave_manage/leave_policy_list", info_a).then(res => {
            let _xtract = Apiconnect.decrypt_obj(res.data.data)
            setList(_xtract)
        })
    }
    useEffect(() => {
        getPolicyList()
    }, [])
    const stats = {1: "🟢", 0: "🔴"}
    // console.log(list)
    var array_month={
        '1':'January','2':'February','3':'March','4':'April','5':'May','6':'June',
        '7':'July','8':'August','9':'September','10':'October','11':'November','12':'December'
    };

    /* ----------------------- Assign Modal ------------------------- */
    const customStyles = {
        content: {
           marginTop: "50px",
           top: '50%', width: '40%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
           height:'80%',
        },
     };

    const applyTemplate = (name, id) => {  
        setTempId(id);
        setTempName(name);
        setModalIsOpen(true);
    }
    
    const getGroupList = async () => {
        setFullScreenLoader(true)
        const inf_b = {company_id : Apiconnect.get_company_id()};
        await Apiconnect.postData(`leave_manage/leave_group_list`, inf_b).then(res => {
            setGrpList(res.data.data)
            setFullScreenLoader(false)
        })
    }
    useEffect(() => {
        getGroupList()
    }, [])

    const applyGrp = async () => {
        const inf_b = {company_id : Apiconnect.get_company_id(), group_id: selectedGrp, policy_id:tempId};
        await Apiconnect.postData('leave_manage/get_leave_policy_group_createupdate', inf_b).then(res => console.log(res.data)).catch(err => console.log(err))
    }

    /* ----------------------- Add Modal ------------------------- */

    const [data_policy, setUser] = useState({
        leavepolicy_name1: "",
        leavepolicy_start_month: "",
        leavepolicy_end_month: "",
        company_id : Apiconnect.get_company_id(),
    });
    console.log(data_policy)

    const {leavepolicy_name1, leavepolicy_start_month, leavepolicy_end_month} = data_policy;

    const onInputChange = e => {
        setUser({ ...data_policy, [e.target.name]: e.target.value ,company_id : Apiconnect.get_company_id()});
    };

    const onSubmit = async e => { 
        setFullScreenLoader(true) 
        e.preventDefault();
        if(editId){
            const inf_d = {leavepolicy_id: editId, policy_title:leavepolicy_name1, leavepolicy_start_month:leavepolicy_start_month, leavepolicy_end_month:leavepolicy_end_month}
            Apiconnect.postData('leave_manage/leave_policy_update', inf_d).then(res => {getPolicyList(); setEditId(0); alert.success("Leave Policy updated"); setUser({leavepolicy_name1: ""}); setFullScreenLoader(false) })
            return
        }
        
        if(list.find(pl => pl.leavepolicy_title.includes(data_policy.leavepolicy_name1))){
            alert.error("Group name already Exists")
            setFullScreenLoader(false)
        }else{
            Apiconnect.postData("leave_manage/leave_policy_create", data_policy).then((response) => {  
                getPolicyList()   
                alert.success("New Policy Added")
                setFullScreenLoader(false)
            });
            
        }    
    }

    const [editId, setEditId] = useState(0)
    
    const handleUpdate = (id) => {  
        
        const newEdit = list.find((item) => item.leavepolicy_pk_id === id)
        console.log(newEdit)
        setUser({leavepolicy_name1: newEdit.leavepolicy_title, leavepolicy_start_month:newEdit.leavepolicy_start_month, leavepolicy_end_month:newEdit.leavepolicy_end_month})
        setEditId(id)
        console.log(id)
    }

    /* ---------------------GET GRPWISE Employee LIST-------------------- */
    const [empList, setEmpList] = useState([])
    const [emp, setEmp] = useState()

    const getEmpList = async (id) => {
        const inf_e = {company_id: Apiconnect.get_company_id(), group_id: id}
       await Apiconnect.postData(`leave_manage/get_groupwise_employee_list`, inf_e).then(res => {     
                    let _xtract = Apiconnect.decrypt_obj(res.data.data)
                    setEmpList(_xtract)
                    // let new_xtract = empList.find(item => item.lgemp_emp_id === emp)
                    // console.log(new_xtract)
                    let ob = {};
                    _xtract.map((val, key) => {
                        if(val.lpemp_emp_id === val.lgemp_emp_id ){
                          ob[val.lpemp_emp_id] = val.lpemp_emp_id
                        }
                        })
                        setSelectedEmp(ob)

            })
        }

        console.log(empList)
    

    /* -----------------------Select employee------------------ */
    //employe select
    const [selectedEmp, setSelectedEmp] = useState([]);
    const [grpId, setGrpId] = useState('')

    const onInputChange2 = (e) => {
     
        if (e.target.checked === true) {
          setSelectedEmp({
            ...selectedEmp,
            [e.currentTarget.name]: e.currentTarget.value,
          });
        } else {
          setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: 0 });
     
        }
      };

      const handleCreate = () => {
        const inf_f = {company_id: Apiconnect.get_company_id(), policy_id: tempId, employee_id: selectedEmp.employee_id, group_id:grpId}
        console.log(inf_f)
        Apiconnect.postData(`leave_manage/get_groupwise_employee_create`, inf_f).then(res => console.log(res.data))
      }

      /* selected employee */
      
      const getSelectedEmp = (id) => {
        const inf_k = {company_id: Apiconnect.get_company_id(), policy_id: tempId, group_id:id}
        Apiconnect.postData(`leave_manage/get_policywise_employee_list`, inf_k).then(res => {
          const _xtract = Apiconnect.decrypt_obj(res.data.data)
          let ob = {}
          _xtract.map((val, key) => {
                ob["id"] = val.lpemp_emp_id
          })
          setEmp(ob.id)
      })
      }



       /* ------------excel----- */

   function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  const saveAsExcel = () => {
    let header = ["Id", "company id", "title", "status", "datetime"]
    setFullScreenLoader(true)

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(list, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      // sheet1.column("D").value("numberFormat")
      sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "leavegrouplist.xlsx");
        setFullScreenLoader(false)
      });
    });
  }

  console.log(list)
      
      

  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
                   {fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    
                    : null
                    }
                    {/* <div>
                        <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between model_head">
                                    <div className="iq-header-title">
                                        <h4 className="card-title ">{tempName}</h4>
                                    </div>
                                </div>

                                <div className="iq-card-body">
                                    <div className="row">
                                        <div className='row' style={{display:'block',width:'100%', padding:'10px'}}>
                                        <button className="btn btn-info" style={{marginLeft:'20px',marginTop: '-10px'}} onClick={applyGrp}>Apply</button>
                                        </div>
                                    
                                        <select className="form-control" name="selectedGrp" value={selectedGrp} onChange={(e) => {setSelectedGrp(e.target.value); getEmpList(e.target.value); setGrpId(e.target.value); getSelectedEmp(e.target.value)}}  >
                                            <option value="" disabled>Select Group</option>
                                            {grpList.map((val, key) => {
                                            return (
                                                <option key={key} value={val.leavegroup_pk_id}>{val.leavegroup_name}</option>
                                            );
                                            })}
                                    </select>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <div className="row">
                                    <table  className="table table-striped  mt-4 center" role="grid" >
                                        <thead className="">
                                            <tr className="clr">
                                                <th></th>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                            empList.map((val, key) => {

                                                var ck = Object.keys(selectedEmp).find(function (element) {
                                                return selectedEmp[element] == val.lgemp_emp_id;
                                                });  
                                                var ck1 = "";
                                                if (ck !== undefined) {
                                                    ck1 = true;
                                                }

                                                return(
                                                    <tr key={key}>
                                                        <td><input type="radio" name="employee_id" value={val.lgemp_emp_id} checked={ck1} onChange={(e) => onInputChange2(e)}/></td>    
                                                        <td>{key + 1}</td>
                                                        <td>{val.first_name} {val.last_name}</td>
                                                    </tr>
                                                )
                                        })
                                        }
                                        </tbody>
                                    </table>
                                    <button className="btn btn-info" onClick={() => handleCreate()}>Create</button>
                                    </div>
                                </div>
                                <div className="text_center">
                                    <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                                </div>
                            </div>
                        </Modal>
                    </div> */}
                    <div className="row">
                        <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Leave Policy List</h4>
                                </div>
                            </div>
                                        
                                        
                            <form onSubmit={e => onSubmit(e)}>
                                <div className="row ml-2 mt-3">
                                    <div className="col-sm-12 col-lg-12"> 

                                        <div class="row">
                                            <div class="form-group col-md-3">
                                                <span>Name</span>
                                                <input 
                                                    type="text" 
                                                    autocomplete="off"
                                                    className="form-control"
                                                    name="leavepolicy_name1"
                                                    onChange={e => onInputChange(e)}  
                                                    placeholder="Policy Name"
                                                    required
                                                    value={leavepolicy_name1}
                                                />

                                            </div>
                                            <div className="col-md-3">
                                                <span>Start Month</span>
                                                <div className="form-group">
                                                    <select className="form-control"  name="leavepolicy_start_month" value={leavepolicy_start_month} onChange={e => onInputChange(e)}>
                                                    <option value="" disabled>Start Month</option>
                                                    <option value="1">January</option>
                                                    <option value="2">February</option>
                                                    <option value="3">March</option>
                                                    <option value="4">April</option>
                                                    <option value="5">May</option>
                                                    <option value="6">June</option>
                                                    <option value="7">July</option>
                                                    <option value="8">August</option>
                                                    <option value="9">September</option>
                                                    <option value="10">October</option>
                                                    <option value="11">November</option>
                                                    <option value="12">December</option>
                                                    </select>
                                                </div>
                                           </div> 
                                            <div className="col-md-3">
                                                <span>End Month</span>
                                                <div className="form-group">
                                                    <select className="form-control"   name="leavepolicy_end_month" value={leavepolicy_end_month} onChange={e => onInputChange(e)} >
                                                    <option value="" disabled>End Month</option>
                                                    <option value="1">January</option>
                                                    <option value="2">February</option>
                                                    <option value="3">March</option>
                                                    <option value="4">April</option>
                                                    <option value="5">May</option>
                                                    <option value="6">June</option>
                                                    <option value="7">July</option>
                                                    <option value="8">August</option>
                                                    <option value="9">September</option>
                                                    <option value="10">October</option>
                                                    <option value="11">November</option>
                                                    <option value="12">December</option>
                                                    </select>
                                                </div>
                                           </div> 
                                            
                                            <div class="col-md-3">
                                                <button className="btn badge badge-pill badge-success" style={{marginTop:25}}>{editId ? "Update" : "Add"}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                                    
                                        
                                        
                            <div className="iq-card-body">
                                <div className='row'>
                                    <div className='col-md-6'></div>
                                    <div className='col-md-6'>
                                        <button className='btn badge badge-pill mr-1 btn-warning float-right' type='button' onClick={() => saveAsExcel()}>Download Policy List Excel</button>
                                    </div>
                                </div>
                            <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                <thead className="">
                                <tr className="clr">
                                    <th scope="col">#</th>
                                    <th scope="col">Policy Name</th>
                                    <th scope="col">Start Month</th>
                                    <th scope="col">End Month</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map((val, key) => {
                                            let leavepolicy_month_start=(typeof array_month[val.leavepolicy_month_start]!='undefined')?array_month[val.leavepolicy_month_start]:'';
                                            let leavepolicy_month_end=(typeof array_month[val.leavepolicy_month_end]!='undefined')?array_month[val.leavepolicy_month_end]:'';
                                            return(
                                                <tr key={key}>
                                                    <td scope="row">{key + 1}</td>
                                                    <td>{val.leavepolicy_title}</td>
                                                    <td>{leavepolicy_month_start}</td>
                                                    <td>{leavepolicy_month_end}</td>
                                                    <td>{stats[val.leavepolicy_status]}</td>
                                                    <td>
                                                        <button style={{marginRight: 10}}  className="btn badge badge-pill badge-success" onClick={() => {handleUpdate(val.leavepolicy_pk_id)}} >Edit</button>
                                                        <Link to={`/LeavePolicyAssignEmp/${val.leavepolicy_title}/${val.leavepolicy_pk_id}`} state={val.leavepolicy_pk_id}><button className="btn badge badge-pill btn-primary mr-1">Assign Employee</button></Link>
                                                        <Link to={`/leave_policy_manages_cat/${val.leavepolicy_pk_id}`}><button style={{marginRight: 10}}  className="btn badge badge-pill badge-info">Manage Category</button></Link>
                                                    </td>
                                                </tr>
                                            )  
                                        })
                                    }
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>
                    </div>
        </div>
      </div>
    </>
  )
}

export default LeavePolicyList