import React, {useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import '../pages/pages.css';
import '../pages/admin.css';
import Axios from 'axios';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const Designation = () => {

   const alert = useAlert();
   let history = useHistory();
   const localuser = JSON.parse(localStorage.getItem('user'));
    let employee_id = Apiconnect.decrypt(localuser.id); 
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
   
   const [info, setInfo] = useState({
      name: "",
      id: null,
      company_id: Apiconnect.get_company_id()
    });
    const { name, id, company_id } = info;
    const onInputChange = e => {
      setInfo({ ...info, [e.target.name]: e.target.value });
    };

    const onSubmit = async e => {
      e.preventDefault();

      if(id>0){
         console.log('in edit function' +id);
         let info_enc =  Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc,employee_id:employee_id };
        // Axios.post(`http://localhost:3050/api/tush/update`, inf_a).then((response) => {
         await Apiconnect.postData(`designation/update`,inf_a).then((response) => {
            console.log(response);
            setInfo({ name: '',  company_id: Apiconnect.get_company_id(), id: ''});
            getCats();
            alert.success(response.data.message);   
         });
         
      }else{
         console.log('in add function.....' );
         let info_enc =  Apiconnect.encrypt_obj(info);
         // console.log(info_enc);
         const inf_a = { enc: info_enc,employee_id:employee_id };
       // Axios.post(`http://localhost:3050/api/tush/create`, inf_a).then((response) => {
          await Apiconnect.postData(`designation/create`,inf_a).then((response) => {
            setInfo({ name: '', city_id: '', id: ''});
            getCats();
            alert.success(response.data.message);   
         });
      }     
    };


    const ondlt = (id) => {
      confirmAlert({
        title: 'Are you sure to Delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => deleteItem(id), 
          },
          {
            label: 'No',
          }
        ]
      });
    };

    const deleteItem = (id) => {
    //  Axios.delete(`http://localhost:3050/api/tush/delete/${id}/${employee_id}`).then((response) => {
             Apiconnect.deleteData(`designation/delete/${id}/${employee_id}/${Apiconnect.get_company_id()}`).then((response) => {
                  getCats();
                  alert.success(response.data.message);      
               });
     
    };


    const getItem = (id) => {    
      Apiconnect.getData(`designation/get/${id}/${Apiconnect.get_company_id()}`).then((response) => {        
         let _xtract = response.data.data;      
         setInfo({ name: _xtract[0].name,company_id:_xtract[0].company_id, id: _xtract[0].id });      
      });
    };

   
   useEffect(() => {getCats();}, []);
   const [desList, setDesList] = useState([]);
   const getCats = () => {
      Apiconnect.getData(`designation/getall/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = response.data.data;
         setDesList(_xtract.reverse());      
      });
   };

   function refreshPage(){ 
      window.location.reload(); 
   }

   return (
      <>
         <div id="content-page" className="content-page">
         <div className="container-fluid">

               <div className="row">
                  <div className="col-sm-12 col-lg-6">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">{id ? 'Designation edit' : 'Designation add'}</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                              <form onSubmit={e => onSubmit(e)}>
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="form-group">
                                    <label>Designation name <span className="required">*</span></label>
                                    <input type="text" className="form-control " required 
                                    name="name"
                                    value={name}
                                    onChange={e => onInputChange(e)} 
                                    placeholder="Designation Name"  />
                                    </div>
                                 </div>
                                 <div className="col-md-12 mt-2">
                                    <button type="submit"  name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                                    {(() => {
                                       if (id>0) {
                                          return (
                                             <button type="button" className="btn" onClick={ refreshPage }> Return </button> 
                                          )
                                       } 
                                    })()}     
                                 </div>
                              </div>
                              </form>
                        </div>
                     </div>
                  </div>

                   <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List of Designation</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <table className="table table-striped table-hover">
                                 <thead className="">
                                    <tr>
                                       <th scope="col">#</th>
                                       <th scope="col">Name</th>
                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody className="">
                                    {desList.map((val, key) => {       
                                       return (
                                          <tr key={key}>  
                                             <th scope="row">{key+1}</th>
                                             <td>{val.name}</td>
                                             <td>
                                             {
                                                user_typ === '3' 
                                                ? (
                                                   <button onClick={() => {ondlt(val.id);}}
                                                   className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>
      
                                                ) 
                                                : null
                                             }
                                            
                                             <button onClick={() => {getItem(val.id);}}
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                             </td>
                                          </tr> 
                                       );
                                    })}                               
                                 </tbody>
                           </table>
                        </div>
                     </div>
                  </div>    
               </div>
         </div>
         </div>
      </>
   )
}

export default Designation