import React, {useState, useEffect } from 'react';
import { useHistory,Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "./ems_pages.css";
import Modal from 'react-modal';


function Conveyance() {


    const alert = useAlert();
    let history = useHistory();
    const local_user = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(local_user.id);

    const [from_date,setFromDate] = useState(new Date());
    const [to_date,setToDate] = useState(new Date());


    const [info, setInfo] = useState({
        emp_id:emp, conveyance_id:'',amount:'',bill_no:'',id: null, company_id: Apiconnect.get_company_id(),
    });
    const { conveyance_id,amount,bill_no,emp_id,id,company_id} = info;
    const onInputChange = e => {
        setInfo({ ...info,[e.target.name]:e.target.value});
    }

    const onSubmit = async e => {
        e.preventDefault();

        if (id > 0) {
            console.log('in edit function' + id);
            info.from_date = format(from_date, 'yyyy-MM-dd');
            info.to_date = format(to_date, 'yyyy-MM-dd');
            info.emp_id = emp;
            info.company_id = Apiconnect.get_company_id();
            let info_enc = Apiconnect.encrypt_obj(info);
            const inf_a = { enc: info_enc };
            await Apiconnect.postData("employee_conveyance/update/", inf_a).then((response) => {
                console.log(response);
                setInfo({
                    conveyance_id:'',amount:'',bill_no:'', id: ''
                });
                alert.success(response.data.message);
                getconveyance();
            });

        } else {
            console.log('in add function.....');
            info.from_date = format(from_date, 'yyyy-MM-dd');
            info.to_date = format(to_date, 'yyyy-MM-dd');
            let info_enc = Apiconnect.encrypt_obj(info);
            const inf_a = { enc: info_enc };
            await Apiconnect.postData("employee_conveyance/create", inf_a).then((response) => {
                setInfo({
                    conveyance_id:'',amount:'',bill_no:'', id: ''
                });
                alert.success(response.data.message);   
                getconveyance();
            });
        }
    }

    useEffect(()=>{ getconveyance();getreimbursement();}, []);
    const [conveyanceList, setConveyanceList] = useState([]);
    const [reimbursemenList, setReimbursemenList] = useState([]);

    const getconveyance = () => {
        Apiconnect.getData(`employee_conveyance/getall`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setConveyanceList(_xtract.reverse());      
         });
    }

    const getreimbursement = () => {
        Apiconnect.getData(`reimbursement_head/getall`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setReimbursemenList(_xtract.reverse());      
         });
    }

    const deleteItem = (id) => {
        Apiconnect.deleteData(`employee_conveyance/delete/${id}`).then((response) => {
            getconveyance();
           alert.success(response.data.message);      
        });
   };
   const getItem = (id) => {    
     Apiconnect.getData(`employee_conveyance/get/${id}`).then((response) => {        
        let _xtract = Apiconnect.decrypt_obj(response.data.data);      
        setInfo({ conveyance_id: _xtract[0].conveyance_id, amount: _xtract[0].amount,bill_no: _xtract[0].bill_no, id: _xtract[0].id });      
        setFromDate(new Date(_xtract[0].from_date));
        setToDate(new Date(_xtract[0].to_date));
     });
      
   };

   const [viewList,setViewList] = useState({
    conveyance_id1:'',amount1:'',bill_no1:'',from_date2:'',to_date2:'',name1:''
 });
 const {  conveyance_id1, amount1,bill_no1,from_date2,to_date2,name1} = viewList;

   const viewItem = (id)=>{
    setModalIsOpen(true);

    Apiconnect.getData(`employee_conveyance/get/${id}`).then((response) => {
       console.log(response.data.data[0]);
       let _xtract = Apiconnect.decrypt_obj(response.data.data);
       console.log(_xtract);
       setViewList({
        conveyance_id1: _xtract[0].conveyance_id, amount1: _xtract[0].amount,bill_no1: _xtract[0].bill_no,from_date2: _xtract[0].from_date1,to_date2: _xtract[0].to_date1,name1: _xtract[0].name
    });
    });
 }

 

 const customStyles = {
    content: {
       top: '50%',
       left: '50%',
       right: 'auto',
       bottom: 'auto',
       marginRight: '-50%',
       transform: 'translate(-50%, -50%)',
      
    },
 };
 const [modalIsOpen, setModalIsOpen] = useState(false);

    console.log(conveyanceList);
    return (
        <>

<div id="content-page" className="content-page">
    <div className="container-fluid">

            
    <div>
                  <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Conveyance View</h4>
                           </div>
                        </div>

                        <div className="iq-card-body">
                           <div className="row">
                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>Conveyance Type : </b></span>
                                    <span>{name1}</span>
                              </div>
                              </div>

                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>Bill No : </b></span>
                                    <span>{bill_no1}</span>
                                 </div>
                              </div>

                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>Amount : </b></span>
                                    <span>{amount1}</span>
                                 </div>
                              </div>


                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>From Date : </b></span>
                                    <span>{from_date2}</span>
                                 </div>
                              </div>

                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>To Date : </b></span>
                                    <span>{to_date2}</span>
                                 </div>
                              </div>

                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>Upload document : </b></span>
                                    <span></span>
                                 </div>
                              </div>
                             
                           </div>
                        </div>
                        <div className="text_center">
                        <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div>


             <div className="row " >
                  <div className="col-md-6">
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Apply Conveyance</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">                    
                            <form className="form-horizontal"  onSubmit={e => onSubmit(e)}>
                                      <div className="form-group row">
                                          <div className="col-sm-4">
                                              <label>Conveyance Type*</label>
                                              <select className="form-control" id="lvtyp" required="" name="conveyance_id" value={conveyance_id} onChange={e=>onInputChange(e)}>
                                                  <option value="">Conveyance Type</option> 
                                                  {reimbursemenList.map((val, key) => {       
                                                    return (
                                                        <option key={key} value={val.id}>{val.name}</option>
                                                    );
                                                    })} 
                                              </select>
                                          </div>
                                          <div className="col-sm-4">
                                              <label>Bill No*</label>
                                              <input type="text" id="from" className="form-control hasDatepicker" name="bill_no" value={bill_no} onChange={e=>onInputChange(e)} required="" placeholder="Bill NO" />
                                          </div>
                                          <div className="col-sm-4">
                                              <label>Amount*</label>
                                              <input type="text" id="from" className="form-control hasDatepicker" name="amount" value={amount} onChange={e=>onInputChange(e)} required="" placeholder="Amount" />
                                          </div>
                                          
                                      </div>
                                      <div className="form-group row">
                                          
                                          <div className="col-sm-6">
                                            <label>From Date*</label>
                                              <DatePicker
                                                selected={from_date}
                                                onChange={e => setFromDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="Date Of Birth"
                                                name="from_date"
                                                required=""
                                                dropdownMode="select"
                                            />
                                          </div>
                                          
                                          <div className="col-sm-6">
                                              <label>To Date*</label>
                                              <DatePicker
                                                selected={to_date}
                                                onChange={e => setToDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="Date Of Birth"
                                                name="to_date"
                                                required=""
                                                dropdownMode="select"
                                            />
                                          </div>
                                          
                                      </div>
                                      <div className="form-group row">
                                          
                                          <div className="col-sm-12">
                                              <label>Upload document*</label>
                                              <input type="file" className="form-control-file"  />
                                          </div>
                                      </div>

                                      
                                    


                                      <div className="form-group row">                                    
                                        <div className="col-sm-12"><button type="submit" className="btn btn-primary">Submit</button></div>
                                        
                                      </div>
                            </form>
                        </div>
                      </div>
                  </div> 

                  <div className="col-sm-12 col-lg-6">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List of category</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <table className="table table-striped table-hover">
                                 <thead className="">
                                    <tr>
                                       <th scope="col">#</th>
                                       <th scope="col">Conveyance Type</th>
                                       <th scope="col">Bill No</th>
                                       <th scope="col">Amount</th>
                                       <th scope="col">From Date</th>
                                       <th scope="col">To Date</th>
                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody className="">
                                    {conveyanceList.map((val, key) => {       
                                       return (
                                          <tr key={key}>  
                                             <th scope="row">{key+1}</th>
                                             <td>{val.name}</td>
                                             <td>{val.bill_no}</td>
                                             <td>{val.amount}</td>
                                             <td>{val.from_date1}</td>
                                             <td>{val.to_date1}</td>
                                             <td>
                                             <button onClick={() => {deleteItem(val.id);}}
                                             className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>

                                             <button onClick={() => {getItem(val.id);}}
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                              
                                              <button onClick={() => viewItem(val.id)}
                                                className="btn btn-info mr-2"><i className="fa fa-eye" aria-hidden="true"></i></button>
                                             
                                             </td>
                                          </tr> 
                                       );
                                    })}                               
                                 </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>

             </div>

               
  </div>





        </>
    )
}

export default Conveyance
