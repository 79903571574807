import React, {useState} from 'react'
import DatePicker from "react-datepicker";
import {Link} from 'react-router-dom'
import { format } from 'date-fns';
import FullScreenLoading from 'react-fullscreen-loading';

const LeaveApply = () => {
    
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    
    const [info, setInfo] = useState({
        from_date: format(new Date(), 'yyyy-MM-dd'),
        to_date: format(new Date(), 'yyyy-MM-dd'),
        category: "",
        reason: "",
        group: ""
    })

    const handleChange = (e) => {
        setInfo({...info, [e.target.name]:e.target.value})
    }

    const handleClick = (e) => { 
        setFullScreenLoader(true)   
        e.preventDefault()
        info.from_date = format(startDate, 'yyyy-MM-dd');
        info.to_date = format(endDate, 'yyyy-MM-dd');
        const inf_a = {
            from_date: info.from_date,
            to_date: info.to_date,
            category: info.category,
            reason: info.reason,
            group: info.group
        }
        console.log(inf_a)
    }

  return (
    <>
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                    {fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    
                    : null
                    }
                <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between model_head">
                        <div className="iq-header-title">
                            <h4 className="card-title ">Leave Apply</h4>
                        </div>
                    </div>
                    <div className="iq-card-body">
                        <div className="border">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={e => setStartDate(e)}
                                            className="form-control"
                                            dateFormat='yyyy-MM-dd'
                                            placeholderText="From"
                                            name="resign_date"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            scrollableMonthDropdown
                                            yearDropdownItemNumber={100}
                                            dropdownMode="select"
                                            />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={e => setEndDate(e)}
                                            minDate={startDate}
                                            className="form-control datelabel"
                                            dateFormat='yyyy-MM-dd'
                                            placeholderText="To"
                                            name="leaving_date"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            scrollableMonthDropdown
                                            yearDropdownItemNumber={100}
                                            dropdownMode="select"
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Leave Category</label>
                                        <select className="form-control" name="category" value={info.category} onChange={e => handleChange(e)} >
                                            <option value="1">Policy</option>
                                            <option value="2">hello</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Group Name</label>
                                        <select className="form-control" name="group" value={info.group} onChange={e => handleChange(e)}>
                                            <option value="john">jon doe</option>
                                            <option value="john1">jon doe</option>
                                            <option value="john2">jon doe2</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <label>Leave Reason</label>
                                        <textarea type='text' className='form-control' placeholder='Enter Leave Reason' rows="4" name='reason' value={info.reason} onChange={e => handleChange(e)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="text_end">
                                <button className="btn btn-success mr-3" onClick={handleClick}>Add</button>
                                <Link to="/LeaveEmployee"><button className="btn btn-info mr-3">Back</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default LeaveApply