import React, { useState, useEffect, Component,useRef } from 'react';
import jsPDF from 'jspdf';
import Apiconnect from "../../services/Apiconnect.js";

const EsiPDF = (props) => {

    let year = props.match.params.year;
    let month = props.match.params.month;
    let months = Number(month) - 1;

    const local_user = JSON.parse(localStorage.getItem('user'));
    let emp_id = local_user.emp_id;
    const [salaryMonthsExcel, setSalaryMonthsExcel] = useState([]);

    const generatePDF = e => {

        var doc = new jsPDF('l', 'pt','a3');
        var name = 'EsiPDF';
        doc.html(document.getElementById('print'), {
        callback: function (doc) {
            doc.save(name);
        },
        x:70,
        y: 10,
        });
    }

    useEffect(()=>{
        loadExcelData(year,month);
       
       },[])

    const loadExcelData = async (year,month) => {
        var yearmon= year+''+month;
        var esiSalaryMonth = await getEsiMonthData(yearmon);
        setSalaryMonthsExcel(esiSalaryMonth);
}

const getEsiMonthData = (yearMonth) => {         
    var salaryData = Apiconnect.getData(`employee_salary/getEsiDataMonthWise/${yearMonth}/${Apiconnect.get_company_id()}`).then((response) => {
          let salData = Apiconnect.decrypt_obj(response.data.data);
          return salData;
      });
      return salaryData;
  }; 

  const mn = [
    'JAN', 'FEB', 'MAR', 'APR',
    'MAY', 'JUN', 'JUL', 'AUG',
    'SEP', 'OCT', 'NOV', 'DEC'
]


console.log('salaryMonthsExcel',salaryMonthsExcel)

  return (
    <>
        <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="iq-card">
                                <div className="iq-card-body"  >
                                <div className="col-md-12 m-t-20" style={{paddingBottom:'30px'}}>
                                        <button  className="btn btn-success mr-2" onClick={e=>generatePDF(e)} style={{cursor: 'pointer',float: 'right'}}><i className="fa fa-download" aria-hidden="true" ></i> Download</button>
                                    </div>
                                    <div className="container mt-5 mb-5"id="print" style={{'backgroundColor': 'unset'}}>
                                        <div className="row" style={{ border: '1px solid black' }}>
                                            <div className="col-sm-12 col-lg-12" style={{ backgroundColor: '#c2d69b', marginBottom: '10px' }}>
                                                <b> {local_user.company_name}</b>
                                                <div className="text-center lh-1 mb-2">
                                                    <h6 className="fw-bold">ESI</h6> <span className="fw-normal">Esi slip for the month of <b>{mn[months]} {year}</b></span>
                                                </div>
                                            </div>

                                            <div className="col-md-12">

                                                {/* <div className="d-flex justify-content-end"> <span>Working Branch:ROHINI</span> </div> */}
                                                <div className="row">
                                                    <div className="row">
                                                        <div className="col-md-6"> </div>
                                                        <div className="col-md-6"> </div>
                                                    </div>
                                                    <table className="mt-4 table table-bordered">
                                                        <thead className="bg-dark text-white">
                                                            <tr>
                                                                <th scope="col">Sr.No.</th>
                                                                <th scope="col">Emp Id</th>
                                                                <th scope="col">emp_code</th>
                                                                <th scope="col">first_name</th>
                                                                <th scope="col">Days_Attended</th>
                                                                <th scope="col">Gross_salary</th>
                                                                <th scope="col">ESI</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                        salaryMonthsExcel.map((value,key)=>{
                                                            return (
                                                            <tr key={key} style={{textAlign:'center'}}>
                                                            <td style={{width:'150px'}} >{key+1}</td>
                                                            <td style={{textAlign:'center'}}>{value.id}</td>
                                                            <td style={{textAlign:'center'}}>{value.emp_code}</td>
                                                            <td style={{textAlign:'center'}}>{value.first_name}</td>
                                                            <td style={{textAlign:'center'}}>{value.Days_Attended}</td>
                                                            <td style={{textAlign:'center'}}>{value.Gross_salary}</td>
                                                            <td style={{textAlign:'center'}}>{value.ESI}</td>
                                                           
                                                        </tr>
                                                            )
                                                        })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
    </>
  )
}

export default EsiPDF;