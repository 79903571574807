import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import "./ems_pages.css";
import "./userstyle.css";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Helper from "../../services/HelperCodebase.js";
import { logDOM } from "@testing-library/react";
import Combine_view_emp_leave_apply from "../../components/combine_view_emp_leave_apply";
 

function  Leave_manage  ()  {
    const alert = useAlert();
    let history = useHistory(); 

    const localuser = JSON.parse(localStorage.getItem('user'));
    let user_id = Apiconnect.decrypt(localuser.id); 
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    const AuthStr = localuser.accessToken; 

    let user_name=localuser.username;
      
    const [leaveList, setleaveList] = useState([]);
    useEffect(() => {   
        getLeaveList(); 
    }, []);
    
    const getLeaveList =async () => {
        await Apiconnect.getData( `employee_leave/getall/${Apiconnect.get_company_id()}/${user_id}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setleaveList(_xtract);
        });
    }



 const leaveCancle = e => {
    let info = { id: e };
    
    Apiconnect.postData(`empLeaveDebit/delete/${e}`, info).then((response) => {
       alert.success(response.data.message);
       getLeaveList();
    });
 }
   

    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">

                        {/*================strat_left_div==================*/}
                        <div className="col-lg-12">
                            <Combine_view_emp_leave_apply
                                options={{ 
                                    title: 'Welcome'
                                }}
                                sel_user_id={user_id} 
                                company_id={Apiconnect.get_company_id()} 
                                sel_user_name={user_name}
                                alert={alert}
                                history={history}
                            />
                        </div>
                        {/*================end_left_div==================*/} 

                        <div className="col-md-12" style={{marginTop:10}}>
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">List of leaves</h4>
                                </div>
                                </div>
                                <div className="iq-card-body padLRTzero">
                                <div className="table-responsive">
                                    <table
                                    id="user-list-table"
                                    className="table table-striped table-bordered"
                                    role="grid"
                                    aria-describedby="user-list-page-info"
                                    >
                                    <thead>
                                        <tr className="clr">
                                        <th>#</th>
                                        <th>Applied On</th>
                                        <th>Category</th>
                                        <th>Reason</th>
                                        <th>Days</th>
                                        <th>Half Day Type</th>
                                        <th>Date</th> 
                                        <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                        
                                        leaveList.map((val, key) => {
                                            var action1 ='';
                                        if (val.status == 0) {
                                            var status = (
                                            <div className=" badge badge-pill badge-warning">
                                                Pending
                                            </div>
                                            );
                                            action1 = <div className="btn badge badge-pill badge-danger" onClick={e => leaveCancle(val.id)}>Cancel</div>;
                                        }
                                        if (val.status == 1) {
                                            var status = (
                                            <div className=" badge badge-pill badge-success">
                                                Approved
                                            </div>
                                            );
                                        }
                                        if (val.status == 2) {
                                            var status = (
                                            <div className=" badge badge-pill badge-danger">
                                                Rejected
                                            </div>
                                            );
                                        } 
                                        return (
                                            <tr key={key}>
                                            <th scope="row">{key + 1}</th>
                                            <td>{val.applied_on}</td>
                                            <td>{val.leave_cat_title}</td>
                                            <td>{val.reason}</td>
                                            <td>{val.leave_count}</td>
                                            <td style={{textAlign:'center'}}>{val.day_typ ? val.day_typ:'-'}</td>
                                            <td>
                                                {val.leave_start_date1} to {val.leave_end_date1}
                                            </td>  
                                            <td>
                                                {status}
                                                {action1}
                                                {/* <div className=" badge badge-pill badge-success">Approved</div>
                                                            <div className=" badge badge-pill badge-danger">Rejected</div>
                                                            <div className=" badge badge-pill badge-warning">Pending</div> */}
                                            </td>
                                            </tr>
                                        );
                                        })}
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}
export default Leave_manage