
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import '../pages/pages.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const Reimbursement = () => {

    const alert = useAlert();
    let history = useHistory();
    const locusr = JSON.parse(localStorage.getItem("user"));
    let user_typ = Apiconnect.decrypt(locusr.emp_typ);

    const [info, setInfo] = useState({
        name: "",
        duration_typ: "monthly",
        taxability: "monthly",
        typ: "fixed",
        tds_head: "",
        id: null,
    });
    const { name, duration_typ, taxability, typ, tds_head, id } = info;
    const onInputChange = e => {
        setInfo({ ...info, [e.target.name]: e.target.value });
    };

    const onSubmit = async e => {
        e.preventDefault();

        if (id > 0) {
            console.log('in edit function' + id);
            let info_enc = Apiconnect.encrypt_obj(info);
            const inf_a = { enc: info_enc };
            Apiconnect.postData(`reimbursement_head/update/`, inf_a).then((response) => {
                history.push("/reimbursement");
                setInfo({ name: "", duration_typ: "", taxability: "", typ: "", tds_head: "", id: '' });
                getRbm();
                alert.success('Reimbursement updated successfully.');
            });
        } else {
            console.log('in add function');
            let info_enc = Apiconnect.encrypt_obj(info);
            const inf_a = { enc: info_enc };
            await Apiconnect.postData("reimbursement_head/create", inf_a);
            history.push("/reimbursement");
            setInfo({ name: "", duration_typ: "", taxability: "", typ: "", tds_head: "", id: '' });
            getRbm();
            alert.success('Reimbursement added successfully.');


        }

    };

    const getItem = (id) => {
        Apiconnect.getData(`reimbursement_head/get/${id}`).then((response) => {
            console.log(response.data.data[0]);
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setInfo({
                name: _xtract[0].name, duration_typ: _xtract[0].duration_typ, typ: _xtract[0].typ, tds_head: _xtract[0].tds_head,
                taxability: _xtract[0].taxability, id: _xtract[0].id
            });

        });
    };


    function refreshPage() {
        window.location.reload();
    }

    const ondlt = (id) => {
        confirmAlert({
          title: 'Are you sure to Delete?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => deleteItem(id), 
            },
            {
              label: 'No',
            }
          ]
        });
      };

    const deleteItem = (id) => {
        Apiconnect.deleteData(`reimbursement_head/delete/${id}`).then((response) => {
            history.push("/reimbursement");
            alert.success('Reimbursement Deleted successfully.');
            getRbm();
        });
    };


    useEffect(() => { getRbm(); }, []);
    const [rbsList, setrbsList] = useState([]);
    const getRbm = () => {
        Apiconnect.getData(`reimbursement_head/getall`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setrbsList(_xtract.reverse());
        });
    };

    const [viewList,setViewList] = useState({
        name1: "",
        duration_typ1: "",
        typ1: "",
        tds_head1: "",
        taxability1: "",
     });
  
     const {  name1, duration_typ1,  typ1, tds_head1, taxability1} = viewList;

    const viewItem = (id) => {
        setModalIsOpen(true);
        Apiconnect.getData(`reimbursement_head/get/${id}`).then((response) => {
            console.log(response.data.data[0]);
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setViewList({
                name1: _xtract[0].name, duration_typ1: _xtract[0].duration_typ, typ1: _xtract[0].typ, tds_head1: _xtract[0].tds_head,
                taxability1: _xtract[0].taxability, id: _xtract[0].id
            });
        });
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    let subtitle;
    const [modalIsOpen, setModalIsOpen] = useState(false);

    let type1 = 'Variable'
    if (typ1 == 'fixed') {
        let type1 = 'Fixed'
    }
    let d_typ1 = 'Yearly';
    if (duration_typ1 == 'monthly') {
        let d_typ1 = 'Monthly';
    }

    let tax1 = 'Yearly';
    if (taxability1 == 'monthly') {
        let tax1 = 'Monthly';
    }


    return (
        <>

            <div id="content-page" className="content-page">
                <div className="container-fluid">

                    <div className="row">

                        <div>

                            <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                                <div className="iq-card">
                                    <div className="iq-card-header d-flex justify-content-between model_head">
                                        <div className="iq-header-title">
                                            <h4 className="card-title ">Earning View</h4>
                                        </div>
                                    </div>

                                    <div className="iq-card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <span><b>Earning Head Name : </b></span>
                                                    <span> {name1}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <span><b>Selection Type : </b></span>
                                                    <span>{d_typ1}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{display:'none'}} >
                                                <div className="form-group">
                                                    <span><b>Balance Amount to be taxed : </b></span>
                                                    <span>{tax1}</span>
                                                </div>
                                            </div>


                                            <div className="col-md-6" style={{display:'none'}} >
                                                <div className="form-group">
                                                    <span><b>Type : </b></span>
                                                    <span>{type1}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-6" style={{display:'none'}} >
                                                <div className="form-group">
                                                    <span><b>TDS Head : </b></span>
                                                    <span>{tds_head1}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="text_center">
                                        <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                                    </div>
                                </div>
                            </Modal>
                        </div>



                        <div className="col-sm-12 col-lg-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Reimbursement Add</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <form onSubmit={e => onSubmit(e)}>
                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Head Name <strong className="required"> *</strong></label>
                                                    <input type="text" className="form-control validate[required]" name="name"
                                                        value={name}
                                                        onChange={e => onInputChange(e)}
                                                        placeholder="Reimbursement Head Name" required />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Selection Type</label>
                                                    <select className="form-control" name="duration_typ" value={duration_typ}
                                                        onChange={e => onInputChange(e)}>
                                                        <option value="monthly">Monthly</option>
                                                        <option value="yearly">Yearly</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6" style={{display:'none'}} >
                                                <div className="form-group">
                                                    <label>Balance Amount to be taxed</label>
                                                    <select className="form-control" name="taxability" value={taxability}
                                                        onChange={e => onInputChange(e)}>
                                                        <option value="monthly">Monthly</option>
                                                        <option value="yearly">Yearly</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6" style={{display:'none'}} >
                                                <div className="form-group">
                                                    <label>Type</label>
                                                    <select className="form-control" name="typ" value={typ}
                                                        onChange={e => onInputChange(e)}>
                                                        <option value="fixed">Fixed</option>
                                                        <option value="variable">Variable</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6" style={{display:'none'}} >
                                                <div className="form-group">
                                                    <label>TDS Head</label>
                                                    <input type="text" className="form-control validate[required]" name="tds_head"
                                                        value={tds_head}
                                                        onChange={e => onInputChange(e)}
                                                        placeholder="Salary" />
                                                </div>
                                            </div>





                                            <div className="col-md-12 m-t-20">
                                                <button type="submit" name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                                                {(() => {
                                                    if (id > 0) {
                                                        return (
                                                            <button type="button" className="btn" onClick={refreshPage}> Cancel </button>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                        </div>



                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">List Of Reimbursement</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <table className="table table-striped table-hover">
                                        <thead className="">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Duration Type</th>
                                                
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            {rbsList.map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <th scope="row">{key + 1}</th>
                                                        <td>{val.name}</td>
                                                        <td>{val.duration_typ}</td>
                                                        
                                                        <td>
                                                        {
                                                            user_typ === '3' 
                                                            ? (
                                                                <button onClick={() => {ondlt(val.id);}}
                                                                className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                                            ) 
                                                            : null
                                                        }
                                                            <button onClick={() => { getItem(val.id); }}
                                                                className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button>

                                                            {
                                                              //<button onClick={() => viewItem(val.id)}
                                                             //   className="btn btn-info mr-2"><i className="fa fa-eye" aria-hidden="true"></i></button>
                                                            }

                                                        </td>
                                                    </tr>
                                                );
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Reimbursement