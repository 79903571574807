import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";

import Apiconnect from "../services/Apiconnect.js";
import { withAlert } from "react-alert";
import Axios from "axios";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.forgot_password = this.forgot_password.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      loading1: false,
      message: "",
    };
  }

  // componentDidMount() {
  //   Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
  //     console.log(response);
  //   })
  // }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();
    this.setState({
      message: "",
      loading: true,
    });
    this.form.validateAll();
    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        (response) => {
          if (response.status == "-1") {
            console.log(response);
            this.setState({
              loading: false,
            });
            this.props.alert.error(response.message);
            return false;
          }
          let emp_typ = Apiconnect.decrypt(response.emp_typ);

          if (emp_typ == 1) {
            this.props.history.push("/dashboard");
          } else if (emp_typ == 3) {
            this.props.history.push("/superadmin");
          } else {
            this.props.history.push("/ems/dashboard");
          }
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  forgot_password(e) {
    this.setState({
      loading1: true,
    });
    if (this.state.username == "") {
      this.props.alert.error("Email Required");
      this.setState({ loading1: false });
      return false;
    } else {
      const inf_a = { email: this.state.username };
      // Axios.post(`http://localhost:3050/api/employee/forgotpassword`, inf_a).then((response) => {
      Apiconnect.postData("employee/forgotpassword", inf_a).then((response) => {
        this.props.alert.success(response.data.message);
        this.setState({ loading1: false });
      });
    }
  }

  render() {
    var img =
      process.env.React_App_BASE_URL_API + "/uploads/logo/school_logo.png";

    return (
      <div
        className="col-md-12"
        style={{
          background:
            'linear-gradient(rgba(34, 40, 47,.9), rgba(80,74,84,.9)), url("' +
            img +
            '")',
          "padding-top": "200px",
          height: "1000px",
        }}
      >
        <div className="card card-container">
          <img src={img} alt="Hrms" className="login-img-card" />

          <Form
            onSubmit={this.handleLogin}
            ref={(c) => {
              this.form = c;
            }}
          >
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <Input
                type="text"
                className="form-control"
                name="username"
                value={this.state.username}
                onChange={this.onChangeUsername}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={this.state.password}
                onChange={this.onChangePassword}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
            </div>

            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>

          <div className="form-group">
            <button
              className="btn btn-info btn-block"
              onClick={this.forgot_password}
              disabled={this.state.loading1}
            >
              {this.state.loading1 && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Forgot Password</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withAlert()(Login);
