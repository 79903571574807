import React from 'react'
import { useState } from 'react'
import Apiconnect from "../../services/Apiconnect.js";
import axios from 'axios';
import { useEffect } from 'react';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import FullScreenLoading from 'react-fullscreen-loading';

const LeaveCycleSettings = () => {
   
   const [fullScreenLoader, setFullScreenLoader] = useState(false);
   const [start_month, setStart_month] = useState("")
   const [end_month, setEnd_month] = useState("")
   const [company_id, setCompany_id] = useState(Apiconnect.get_company_id())
   const [all, setAll] = useState([])
   const [editId, setEditid] = useState(0)

    /* -----------GET ALL DATA START---------- */
    const getAll = async () => {
      setFullScreenLoader(true)
      const inf_a = {company_id : Apiconnect.get_company_id()};
      await Apiconnect.postData(`leave_manage/leave_cycle_setting_list`, inf_a).then((response) => {
         setFullScreenLoader(false)
         setAll(response.data.data)
      })
    }
    useEffect(() => {
      getAll()
      }, [])
    console.log(all)


    // Submit
    const handleSubmit = async (e) => {
      setFullScreenLoader(true)  
      e.preventDefault()
        if (editId){
         const inf_a1 = {start_month, end_month, setting_id: editId };   
         console.log(editId)
         await Apiconnect.postData(`leave_manage/leave_cycle_setting_update`, inf_a1).then(res => {
            setFullScreenLoader(false)
            getAll()
         } )
         setEnd_month("")
         setStart_month("")
         setEditid(0)
         return
         }
         const inf_a2 = {start_month, end_month, company_id}
         start_month.length > 0 && end_month.length > 0 && await Apiconnect.postData(`leave_manage/leave_cycle_setting_create`, inf_a2).then(res => {
            getAll()
            console.log(res.data)
            setFullScreenLoader(false)
         })
         console.log("New Added")    
         setEnd_month("")
         setStart_month("")
         // setCompany_id("")    
      }

    //update
    const handleUpdate = (id) => {
      const newEdit = all.find(item => item.lcsett_pk_id === id)
      setEnd_month(newEdit.lcsett_month_end)
      setStart_month(newEdit.lcsett_month_start)
      setCompany_id(newEdit.lcsett_company_id)
      setEditid(id)
    }

    //delete
   const handleDelete = async (id) => {
         const inf_a3 = {setting_id: id}; 
         setFullScreenLoader(true)
         await Apiconnect.postData(`leave_manage/leave_cycle_setting_delete`, inf_a3).then(res => {
            getAll()
            setFullScreenLoader(true)
         }).catch(err => console.log(err))  
   }
   const m = {1: "January", 2: "February", 3:"March", 4:"April", 5:"May", 6:"June", 7:"July", 8:"August", 9:"September", 10:"October", 11:"November", 12: "December"}
   const a = {0: <i style={{color: "red"}} class="fa-solid fa-xmark"></i> , 1: <i style={{color: "green", fontWeight: 'bolder', fontSize: 20}} class="fa-sharp fa-solid fa-check"></i>}
   const n = {6: "Seth Anandram Jaipuria School Kanpur"}

  const allexcel = all.map((item) => ({
   companyid: item.lcsett_company_id,
   start_month: item.lcsett_month_start,
   end_month: item.lcsett_month_end,
   status:item.lcsett_status,
   type:item.lcsett_type

  }))
    /* ------------excel----- */
    function getSheetData(data, header) {
       var fields = Object.keys(data[0]);
       var sheetData = data.map(function (row) {
         return fields.map(function (fieldName) {
           return row[fieldName] ? row[fieldName] : "";
         });
       });
       sheetData.unshift(header);
       return sheetData;
     }

     const saveAsExcel = () => {
      let header = ["companyid", "start_month", "end_month", "status", "type"]
      setFullScreenLoader(true)
  
      XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const sheetData = getSheetData(allexcel, header);
        const totalColumns = sheetData[0].length;
  
        sheet1.cell("A1").value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        // sheet1.column("D").value("numberFormat")
        sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        range.style("border", true);
        return workbook.outputAsync().then((res) => {
          saveAs(res, "arealist.xlsx");
          setFullScreenLoader(false)
        });
      });
    }
    const local_user = JSON.parse(localStorage.getItem('user'));
  return (
    <>
         <div id="content-page" className="content-page">
         <div className="container-fluid">
                  {fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    
                    : null
                    }
               <div className="row">
                  <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Leave Cycle Settings</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                              <form >
                              <div className="row">
                                 <div className="col-md-4">
                                    <div className="form-group">
                                        <label>From <span className="required">*</span></label>
                                        <select className="form-control"  name="start_month" required value={start_month} onChange={e => setStart_month(e.target.value)}>
                                          <option value="" disabled>Start Month</option>
                                          <option value="1">January</option>
                                          <option value="2">February</option>
                                          <option value="3">March</option>
                                          <option value="4">April</option>
                                          <option value="5">May</option>
                                          <option value="6">June</option>
                                          <option value="7">July</option>
                                          <option value="8">August</option>
                                          <option value="9">September</option>
                                          <option value="10">October</option>
                                          <option value="11">November</option>
                                          <option value="12">December</option>
                                        </select>
                                       
                                    </div>
                                 </div>
                                 <div className="col-md-4">
                                       <div className="form-group">
                                          <label>To<span className="required">*</span></label>
                                          <select className="form-control" name="end_month" required value={end_month} onChange={e => setEnd_month(e.target.value)}>
                                             <option value="" disabled>End Month</option>
                                             <option value="1">January</option>
                                             <option value="2">February</option>
                                             <option value="3">March</option>
                                             <option value="4">April</option>
                                             <option value="5">May</option>
                                             <option value="6">June</option>
                                             <option value="7">July</option>
                                             <option value="8">August</option>
                                             <option value="9">September</option>
                                             <option value="10">October</option>
                                             <option value="11">November</option>
                                             <option value="12">December</option>
                                          </select>
                                          
                                       </div>
                                 </div>
                                 <div className="col-md-12 mt-2">
                                    { !all.length > 0 && <button className="btn badge badge-pill badge-success" onClick={handleSubmit}>Add</button> }
                                    { editId ? <button className="btn badge badge-pill badge-success" onClick={handleSubmit}>Update</button> : ""   }
                                 </div>
                              </div>
                              </form>

                              <div className="row">

                              <div className="table-responsive">
                                 <div className='row'>
                                    <div className='col-md-6'></div>
                                    <div className='col-md-6'>
                                       <button className='btn badge badge-pill mr-1 btn-warning float-right' type='button' onClick={() => saveAsExcel()}>Download leave cycle excel</button>
                                    </div>
                                 </div>
                                 <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                    <thead>
                                       <tr className="clr" >
                                          <th>#</th>
                                          <th>Company</th>
                                          <th>Start Month</th>
                                          <th>End Month</th>
                                          <th>Status</th>
                                          <th>Type</th>
                                          <th>Actions</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {all.map((val, key) => {

                                          return (
                                             <tr key={key}>
                                                <th scope="row">{key + 1}</th>
                                                <td>{local_user.company_name}</td>
                                                <td>{m[val.lcsett_month_start]}</td>
                                                <td>{m[val.lcsett_month_end]}</td>
                                                <td><div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>{a[val.lcsett_status]}</div></td>
                                                <td>{val.lcsett_type}</td>
                                                <td>
                                                   <button style={{marginRight: 10}} onClick={() => handleUpdate(val.lcsett_pk_id)} className="btn badge badge-pill badge-success">Edit</button>
                                                   <button className='btn badge badge-pill badge-danger' onClick={() => handleDelete(val.lcsett_pk_id)}>delete</button>
                                                </td>
                                             </tr>
                                          );
                                       })}
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
         </div>
         </div>
      </>
  )
}

export default LeaveCycleSettings