import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from 'axios';
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert'; // Import
import FullScreenLoading from 'react-fullscreen-loading';

function Team_compoff_request() {

   const alert = useAlert();
   const localuser = JSON.parse(localStorage.getItem('user'));
   let user_id = Apiconnect.decrypt(localuser.id);
   let company_id=Apiconnect.get_company_id();
   const [data_user, set_data_user] = useState([]); 
   const [list_compensatory_off, set_list_compensatory_off] = useState([]);
   const [fullScreenLoader, setFullScreenLoader] = useState(false);
   const [res_cat_det, setres_cat_det] = useState([])
   const [data_category, set_data_category] = useState([])

   useEffect(() => { 
      getprofile();
      get_compensatory_off_list(); 
      get_lc_compensatory_off_det(); 
   }, []);  
   
   const getprofile =async () => {
      await Apiconnect.getData(`employee/get/${user_id}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);  
         set_data_user(_xtract);  
         get_compensatory_off_list(_xtract[0].reporting_officer);
      });
   }

   let reporting_officer=(typeof data_user[0]!='undefined')?data_user[0].reporting_officer:0;  
   
   const get_compensatory_off_list =async (sel_rept_id='') => {   
      let data_post1={'employee_id':sel_rept_id}; 
      setFullScreenLoader(true);
      await Apiconnect.postData(`employee_compensatoryoff/get_compensatory_off_lists`,data_post1).then((response) => {
         setFullScreenLoader(false);
         let _xtract = ((typeof response.data.data!='undefined')?Apiconnect.decrypt_obj(response.data.data):[]); 
         set_list_compensatory_off(_xtract);
      });
   };

   const get_lc_compensatory_off_det =async () => {   
      let data_pst1 ={company_id:company_id}; 
      await Apiconnect.postData(`employee_compensatoryoff/get_leave_category_details_compoff`,data_pst1).then((response) => {
         setres_cat_det(Apiconnect.decrypt_obj(response.data.data));   
      });
   }; 
    
   const compoff_appove_reject =async (ecompoff_pk_id,ecompoff_emp_id,sel_status) => {  
      confirmAlert({
         customUI: ({ onClose }) => {
           return (
            <div className='react-confirm-alert'>
               <div className='react-confirm-alert-body' style={{textAlign:'center'}}>   
                  <h1>Are you sure?</h1>
                  <p>You want to {sel_status==1?'Approve':'Reject'} this?</p> 
                  <button className="btn badge badge-pill badge-danger" onClick={onClose}>Close</button>
                  <button className="btn badge badge-pill badge-success" style={{marginLeft:20}}
                     onClick={ async ()  => {  
 
                        let array_data_category=[];
                        let array_leave_credit=[];
                        let data_pst11 ={ecompoff_pk_id:ecompoff_pk_id}; 
                        await Apiconnect.postData(`employee_compensatoryoff/get_lc_compensatory_off_byids`,data_pst11).then((response) => {
                           array_data_category=Apiconnect.decrypt_obj(response.data.data);   
                        });


                        let leave_cat_id=(typeof res_cat_det[0]!='undefined')?res_cat_det[0].leave_cat_id:0;
                        let ecompoff_date_workedon=(typeof array_data_category[0]!='undefined')?array_data_category[0].ecompoff_date_workedon:0;
                        let ecompoff_leave_credit_id=(typeof array_data_category[0]!='undefined')?array_data_category[0].ecompoff_leave_credit_id:0;

                        if(leave_cat_id==0){
                           alert.error('Please Add Category');
                           return false;
                        }
 
                        if(ecompoff_date_workedon!=0){
                           if(sel_status==1){
                              let data_pst2 ={ 
                                 company_id: company_id, 
                                 emp_id: ecompoff_emp_id,
                                 typ:'credit',
                                 leave_typ:3,
                                 credit_ym:moment(new Date(ecompoff_date_workedon)).format("YYYY-MM-DD"),
                                 applied_on:moment(new Date(ecompoff_date_workedon)).format("YYYY-MM-DD"),
                                 leave_count:1,
                                 empleave_category_id:leave_cat_id,
                                 credited_by:user_id,
                                 empleave_type:'compoff',
                              }; 
                              // console.log(data_pst2);
                              await Apiconnect.postData(`empLeaveCredit/create/${ecompoff_emp_id}`, data_pst2).then(response => { 
                                 array_leave_credit=(typeof response.data.data!='undefined')?Apiconnect.decrypt_obj(response.data.data):[];     
                              });
                           }else if(sel_status==2 && ecompoff_leave_credit_id!=0){ 
                              let data_pstd={ecompoff_leave_credit_id:ecompoff_leave_credit_id}; 
                              array_leave_credit=[];
                              await Apiconnect.postData(`employee_compensatoryoff/delete_leave_credit`, data_pstd).then(response => { 
                                  
                              });
                           }  
                            
 
                           let leavecredit_insertId=(typeof array_leave_credit.insertId!='undefined')?array_leave_credit.insertId:0;
                         
                           let data_pst3 ={ ecompoff_pk_id: ecompoff_pk_id, ecompoff_status: sel_status,ecompoff_leave_credit_id:leavecredit_insertId}; 
                           
                           Apiconnect.postData("employee_compensatoryoff/compensatory_off_status_update", data_pst3).then((response) => {
                              alert.success(response.data.message);
                              get_compensatory_off_list(reporting_officer);
                           }); 
                             
                        }                  
                        onClose();
                     }}
                  >
                     Yes, {sel_status==1?'Approve':'Reject'} !
                  </button>
               </div>
            </div>
           );
         }
      }); 
   } 

   
   
   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid"> 
               {  
                  fullScreenLoader ? (
                     <FullScreenLoading loading loaderColor="#3498db" />
                  ) : null
               }
               <div className="row">
                  <div className="col-sm-12">
                     <div className="iq-card">
                        <div>
                           <h4 className="card-title hd">Team Compensatory Off Request</h4>
                        </div>
                        <div className="iq-card-body">
                           <div className="row">

                              <div className="table-responsive">

                                 <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                    <thead>
                                       <tr className="clr" >
                                          <th>#</th>
                                          <th>Emp. Name</th>
                                          <th>Date</th>
                                          <th>Reason</th>
                                          <th>Status</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          list_compensatory_off.length >0 ?(
                                             list_compensatory_off.map((val_compoff, key) => {
                                                let ecompoff_pk_id=val_compoff.ecompoff_pk_id;
                                                let ecompoff_emp_id=val_compoff.ecompoff_employee_id;
                                                let var_status1='';
                                                let var_status2='';
                                                let var_action1='';
                                                let var_action2='';let var_action3='';
                                                let ecompoff_date_workedon1=moment(new Date(val_compoff.ecompoff_date_workedon)).format("DD-MM-YYYY");
                                                 
                                                var_action1= <div className="btn badge badge-pill badge-success" onClick={e => compoff_appove_reject(ecompoff_pk_id,ecompoff_emp_id,1)}>Approve</div>;
                                                var_action2= <div className="btn badge badge-pill badge-danger"  onClick={e => compoff_appove_reject(ecompoff_pk_id,ecompoff_emp_id,2)}>Reject</div>;
                                                
                                                var_status1=<div className=" badge badge-pill badge-warning">Pending</div>;
                                                var_action3=var_action1;
                                                if(val_compoff.ecompoff_status_action==1){
                                                   var_status1=<div className=" badge badge-pill badge-success">Approved</div>;
                                                   var_action3=var_action2;
                                                }
                                                if(val_compoff.ecompoff_status_action==2){
                                                   var_status1=<div className=" badge badge-pill badge-danger">Rejected</div>;
                                                   var_action3=var_action1;
                                                }


                                                return (
                                                   <tr key={key}>
                                                      <td scope="row">{key + 1}</td>
                                                      <td>{val_compoff.first_name}</td>
                                                      <td>{ecompoff_date_workedon1}</td>
                                                      <td>{val_compoff.ecompoff_date_reason}</td>
                                                      <td>{var_status1}</td>
                                                      <td>{var_action3}</td>
                                                   </tr>
                                                );
                                             })
                                          ):
                                          <tr>
                                             <th colSpan={6} style={{textAlign:'center'}}>No Data Found..</th>
                                          </tr>
                                       }
                                    </tbody>
                                 </table>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
        </div> 
      </>
   )
}

export default Team_compoff_request
