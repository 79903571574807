import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import "react-datepicker/dist/react-datepicker.css";
import InputTextField from "./input_text.js";  
import { Link } from 'react-router-dom';

const Employee_manage_salary_info = (props) => {
    let empid = props.match.params.id;
    const alert = useAlert();

    //////////USE EFFECT
    useEffect(() => 
        {
            getDList();
            getdeductList(); 
            getreimbursementList();
        }, []
    ); 

    ////////
    //const [earningList, setearningList] = useState([]);
    
    ////EARNING
        let ern = [];
        const [info, setInfo] = useState([]);        
        const getDList = () => {
            Apiconnect.getData(`employee_salary/getAllhead/${empid}`).then((response) => {
                let ern = Apiconnect.decrypt_obj(response.data.data);
                console.log(ern);
                setInfo(ern); 
            });
        };
        
        const onInputChange = (e,i) =>{
           
            console.log('====++++>>>=====');
            console.log(info); 
            let ki = i.key;
            let markers = [...info];
            markers[ki] = {...markers[ki], amount: e.currentTarget.value};
            setInfo(markers);
            console.log(info); 
            console.log('====++++====='); 

            deduct_head.map((vk, kk) => {
                if(vk.formula_id>0){
                    console.log(kk);
                    console.log(vk.formula_id);
                    formula_man(kk,vk.formula_id);
                } 
            })



        };
    ///////--------------->>>>EARNING END -----


    /////DEDUCT
        const [deduct_head, setDeduct_head] = useState([]); 
        const getdeductList = () => {
            Apiconnect.getData(`employee_salary/getDeducthead/${empid}`).then((response) => {
                console.log(response.data); 
                let _xtract = Apiconnect.decrypt_obj(response.data.data);
                //console.log(_xtract);
                setDeduct_head(_xtract);
            });
        };
        const onInputChange_deduct = (e,i) =>{
            let ki = i.keyx;
            let markers = deduct_head;        
            markers[ki] = {...markers[ki], amount: e.currentTarget.value};
            setDeduct_head(markers); 
        };
    ///////--------------->>>>DEDUCT END -----




    ///REMBERSEMENT
        const [reimbursement_head, setReimbursement_head] = useState([]); 
        const getreimbursementList = () => {
            Apiconnect.getData(`employee_salary/getreimbursementhead/${empid}`).then((response) => {
                ////console.log(response.data); 
                let _xtract = Apiconnect.decrypt_obj(response.data.data);
                setReimbursement_head(_xtract);
            });
        };        
        const onInputChange_remb = (e,i) =>{
            let ki = i.keyy;
            let markers = reimbursement_head;        
            markers[ki] = {...markers[ki], amount: e.currentTarget.value};
            setReimbursement_head(markers); 
        };
    ///////--------------->>>>REMBERSEMENT END -----













    const changeFormula = (e,i) => {
        //setInfo({ ...info, [e.target.name]: e.target.value });
        let ki = i.keyx;
        let va = e.target.value; 
        formula_man(ki,va);

    };

    const  formula_man = (ki,va) =>{
        Apiconnect.getData(`employee_salary/get_formula/${va}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);            
            var logic = _xtract[0].logic;
  
            var itm = {};
            info.map((val, key) => {
                let kkn = val.abbreviation;
                itm[kkn] = val.amount;
            });          
            const kkk = Object.keys(itm);
            const vvv = Object.values(itm); 
            var out =0;
            logic.map((v, k) => {
              var if_con = v.if_condition;
              var chk = v.check_value;
              var opr = v.f_operator;
  
              kkk.map((v1, k1) => {
                var finder = v1;
                var rplcer = vvv[k1];
  
                var rr = if_con.replace(finder, rplcer);
                if_con = rr.toString();
              });
  
                var ifcon_o = eval(if_con);
                if(opr=='BETWEEN'){ 
                    var rng = chk.split("-");                   
                    var ifcon_out = ifcon_o +'>='+ rng[0]+ ' && ' + ifcon_o +'<='+ rng[1];
                }else{
                    var ifcon_out =   ifcon_o+opr+chk;
                }
                //console.log(ifcon_out);
                //console.log(eval(ifcon_out));
  
                if(eval(ifcon_out)){
                    out = v.f_then;

                    kkk.map((v1, k1) => {
                        var finder = v1;
                        var rplcer = vvv[k1];
                        var rr = out.replace(finder, rplcer);
                        out = rr.toString();


                    });
                }

            });            
            console.log('::: PTAX -->::'+out);
  
  
            
            let markers = [...deduct_head]; 
            markers[ki] = {...markers[ki], amount: out};
            markers[ki] = {...markers[ki], formula_id: va};
            setDeduct_head(markers);	
            
        });
    }




    //console.log('---|||>>>-------');
    //console.log(info); 
    //console.log(deduct_head); 
    //console.log(reimbursement_head); 
    //console.log('------|\\|----');  


    const submitForm = async e => {          
         e.preventDefault(); 

         let info_enc =  Apiconnect.encrypt_obj(info); 
         let deduct_head_enc =  Apiconnect.encrypt_obj(deduct_head); 
         let remb_head_enc =  Apiconnect.encrypt_obj(reimbursement_head);  
          
         const inf_a = { enc: info_enc, enc_deduct: deduct_head_enc, enc_remb: remb_head_enc, emp_id: empid };
         //console.log(inf_a);
         
         await Apiconnect.postData("employee_salary/create",inf_a).then((response) => {
            console.log(response);
            //console.log('|||||||---||||||');
            alert.success(response.data.message);    
         });
         
         
          
  
     };




    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">Employee Salary Info</h4>
                            </div>
                        </div>
                        <div className="iq-card-body">
                        <form onSubmit={e => submitForm(e)}>

                                <div className="border">
                                <div className="row">


                            <table className="table table-hover">
                              <thead>
                                 <tr>
                                    <th width="120" scope="col">#</th>
                                    <th scope="col">Head</th>                                    
                                    <th scope="col">Type</th>                                    
                                    <th scope="col">Current Amount</th>                                    
                                    <th width="320" scope="col">Set Amount</th>                                    
                                 </tr>
                              </thead>
                              <tbody>
                                    
                                    
                                    <tr>
                                        <td colSpan="3"><h6>Earning Head</h6></td>  
                                    </tr>
                                    {info.map((val, key) => {
                                        let frmla = val.formula;
                                    return ( 
                                            <tr  key={key}>
                                                <td>{key+1}</td>
                                                <td>{val.name}  </td>
                                                <td>
                                                    <select>
                                                    <option>Static Data</option>
                                                    {frmla.map((valza, keyza) => {
                                                             return (<option value={valza.id}>{valza.title}</option>);
                                                        })} 
                                                    </select>
                                                </td>
                                                <td>{val.amount} </td>
                                                <td> 
                            
                                                    <input name={val.id} defaultValue={val.amount}   onChange={e => onInputChange(e,{key})} /> 
                                                
                                                </td>
                                            </tr> 
                                        );
                                    })}   


                                    <tr>
                                        <td colSpan="3"><h6>Deduction Head</h6></td>  
                                    </tr>

                                    {deduct_head.map((valx, keyx) => {
                                        let frml = valx.formula;
                                    return ( 
                                            <tr  key={keyx}>
                                                <td>{keyx+1}</td>
                                                <td>{valx.name} .... </td>
                                                <td>
                                                    <select value={valx.formula_id} onChange={e => changeFormula(e,{keyx})} >
                                                        <option value="">Static Data</option>
                                                        {frml.map((valz, keyz) => {
                                                             return (<option key={keyz} value={valz.id}>{valz.title}</option>);
                                                        })} 
                                                    </select>
                                                </td>
                                                <td>{valx.amount} </td>
                                                <td> 
                            
                                                    <input name={valx.id} value={valx.amount}   defaultValue={valx.amount}  onChange={e => onInputChange_deduct(e,{keyx})} /> 
                                                
                                                </td>
                                            </tr> 
                                        );
                                    })} 


                                      

                                    <tr>
                                        <td colSpan="3"><h6>Reimbursement Head</h6></td>  
                                    </tr>

                                    {reimbursement_head.map((valy, keyy) => {
                                    return ( 
                                            <tr  key={keyy}>
                                                <td>{keyy+1}</td>
                                                <td>{valy.name}  </td>
                                                <td>
                                                     
                                                </td>
                                                <td>{valy.amount} </td>
                                                <td> 
                            
                                                    <input name={valy.id}   defaultValue={valy.amount}  onChange={e => onInputChange_remb(e,{keyy})} /> 
                                                
                                                </td>
                                            </tr> 
                                        );
                                    })}                                                                   
                                     

 

                              </tbody>
                            </table>
                             

                          

                             
                            </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                    <button type="submit"  name="id"   className="btn btn-primary mr-2">  Proceed </button>
                                    <Link style={{float:'right'}} className="btn btn-secondary"  to="/employee_manage_list" >Back to List</Link>
                                       
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )



}
export default Employee_manage_salary_info