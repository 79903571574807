import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import jsPDF from 'jspdf'
import imageToBase64 from 'image-to-base64/browser';
var JsBarcode = require('jsbarcode');
  

function IdCard_view2(props) {
    let history = useHistory();
    const local_user = JSON.parse(localStorage.getItem('user'));
    const [empListAll, setEmpListAll] = useState(props.empListAll);
    const [companyData, setCompanyData] = useState(props.companyData);

    // console.log('empListAll2',empListAll)
  
    useEffect(() => {
     loadData()
    }, []);

    
    const loadData = async () => {
        const arr = [];
        for(let i = 0;i < empListAll.length;i++){
            
            if (empListAll[i].display_picture == null || empListAll[i].display_picture == '') {

                if(empListAll[i].gender === 'Male'){
                    var profile_img = "/images/user/1.jpg";
                }else{
                    var profile_img = "/images/user/2.jpg";
                }
              } else {
                const min = 1;
                const max = 1000;
                // var rand = min + Math.random() * (max - min);
                var profile_img =
                  process.env.React_App_BASE_URL_API +
                  "/uploads/thumbdp/" +
                  empListAll[i].display_picture;
              }

            //   if(empListAll[i].gender === 'Male'){
            //     var profile_img = "/images/user/1.jpg";
            // }else{
            //     var profile_img = "/images/user/2.jpg";
            // }

            let base_strings = imageToBase64(profile_img)
            .then((response) => response)
            let image = await resolve(base_strings);
           
            empListAll[i]['img'] = image
            arr.push(empListAll[i])
            let barcode = await textToBase64Barcode(empListAll[i].emp_code)
            const rotateBarcode1 = await rotateBarcode(barcode, 90)
            empListAll[i]['barcode'] = rotateBarcode1  
            console.log(empListAll.length-1, i)
            if(empListAll.length-1 === i){
                setEmpListAll([...arr])
            }
        }
    }

    const resolve = file => {
        return new Promise(resolve => {
          resolve(file);
        });
      };

    // const generatePDF = e => {
        
            
    //    var doc = new jsPDF('p', 'pt',[330,680]);
  
       
    //     doc.html(document.getElementById('print'), {
    //         x:50,
    //         y: 10,
    //     callback: function (doc) {
    //         doc.save('idcard');
    //     },
       
    //     });

    //     // _.each( $('.pt'), function(e) {
    //     //     doc.html(e);
    //     //     doc.addPage();
    //     //     console.log(e)
    //     // });

    //     // setTimeout(() => {
    //     //     console.log('teest')
    //     //     doc.save();
    //     //   }, "6000")

       
    //         }

      function textToBase64Barcode(text){
        var canvas = document.createElement("canvas");
        JsBarcode(canvas, text, {displayValue: false, width: 0.6,height:20,margin:0, background: '#ffffff'});
        return canvas.toDataURL("image/png");
      }

      const rotateBarcode = (srcBase64, degrees) => {
        return new Promise((resolve, reject) => {

            const canvas = document.createElement('canvas');
            const ctx    = canvas.getContext('2d');
            const image  = new Image();
            image.onload = function () {
              canvas.width  = degrees % 180 === 0 ? image.width : image.height;
              canvas.height = degrees % 180 === 0 ? image.height : image.width;
              ctx.translate(canvas.width / 2, canvas.height / 2);
              ctx.rotate(degrees * Math.PI / 180);
              ctx.drawImage(image, image.width / -2, image.height / -2);
              resolve(canvas.toDataURL())
            };
             image.src = srcBase64;

          
        })
      }


    return (
        <>
                    <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="iq-card">
                                <div className="iq-card-body"  >

                                {/* <div className="col-md-12" style={{marginTop:'40px'}}>
                                        <button  className="btn btn-success mr-2" onClick={e=>generatePDF2(e)} style={{cursor: 'pointer',float: 'right'}}><i className="fa fa-download" aria-hidden="true" ></i> Download</button>
                                    </div> */}

                                        {
                                        empListAll.map((val, key) => {

                                            return (
                                                <>
                                            <div key={key} className="pt" id={'print'+key} style={{width:'100%',marginBottom:'20px'}}>
                                                
                                               <div  style={{ border: '1px solid black',width:'55mm',height:'85mm',padding: '10px 15px',marginBottom:'20px' }}>
                                          <div style={{backgroundColor:'#f0f8ff61'}}>
                                            <div style={{width:'100%',display:'flex'}}>
                                            <div style={{width:'30%'}}>
                                                <img style={{width: '50px'}} src={val.logo}/>
                                                </div>
                                                <div style={{width:'70%',textAlign:'center',margin: 'auto',fontFamily: 'serif'}}>
                                                    <div style={{fontSize:'12px',color: 'blue'}}>{companyData[0].company_name.split(' ').slice(0,2).join(' ').toUpperCase()}</div>
                                                    <div style={{fontSize:'13px',marginTop: '-5px',fontWeight: 'bold', color: 'blue'}}>{companyData[0].company_name.split(' ').slice(2,4).join(' ').toUpperCase()}</div>
                                                </div>
                                            </div>  
                                            <div style={{width:'100%',margin:'5px 0px'}}>
                                                <div 
                                                    style={{
                                                        backgroundColor:'#edb415', 
                                                        fontSize:'10px',
                                                        fontFamily: 'monospace',
                                                        letterSpacing: '2px',
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        paddingTop: '1px'
                                                        }}>
                                                    EMPOWER ENTHUSE EXCEL
                                                </div>
                                                <div 
                                                    style={{
                                                        fontSize:'11px',
                                                        fontFamily: 'serif',
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        paddingTop: '3px'
                                                        }}>
                                                   {companyData[0].landmark.toUpperCase()}
                                                </div>
                                            </div> 
                                          </div>

                                          <div 
                                            style={{
                                                width:'100%', 
                                                display:'flex',
                                                marginTop:'15px',
                                                height: '35%',
                                                position: 'relative'
                                                }}>
                                              <div style={{width:'30%',position:'absolute'}}>
                                              <img style={{marginLeft: '5px',right: '0mm', bottom: '0mm'}} src={val.barcode}/>
                                              {/* <svg style={{transform: 'rotate(90deg)'}} ref={inputRef} /> */}
                                              </div>  
                                              <div style={{width:'70%',margin: 'auto',textAlign: 'end'}}>
                                              <img style={{width: '80%',marginTop: '-10px'}} src={'data:image/png;base64,'+val.img}/>
                                              </div>          
                                          </div>
                                         
                                         <div style={{width:'100%'}}>
                                            <div 
                                              style={{
                                                textAlign:'center',
                                                fontSize:'14px',
                                                fontFamily: 'serif',
                                                fontWeight: 'bold',
                                                marginTop:'3px',
                                                color: 'brown'
                                                }}>
                                               {val.first_name+' '+val.last_name}
                                            </div>
                                            <div 
                                             style={{
                                                textAlign:'center',
                                                fontSize:'14px',
                                                fontFamily: 'serif',
                                                fontWeight: 'bold',
                                                marginTop:'-3px'
                                                }}>
                                              {val.emp_designation}
                                            </div>
                                            <div 
                                             style={{
                                                textAlign:'center',
                                                fontSize:'14px',
                                                fontFamily: 'serif',
                                                fontWeight: 'bold',
                                                marginTop:'-3px'
                                                }}>
                                                Emp. Id.: {val.emp_code}
                                            </div>
                                         </div>

                                         <div style={{width:'100%',marginTop: '10px'}}>
                                            <div style={{
                                                backgroundColor: '#ffd400c7',
                                                height: '10px'
                                            }}>
                                            </div>
                                            <div style={{
                                                backgroundColor: 'blue',
                                                height: '12px'
                                            }}>
                                            </div>
                                         </div>

                                        </div>

                                        <div  style={{ width:'20px'}}>
                                        </div>
                                        
                                        <div  style={{ border: '1px solid black',width:'55mm',height:'85mm',padding: '10px 15px' }}>
                                           
                                          <div style={{width:'100%',textAlign:'center',margin: 'auto',fontFamily: 'serif',paddingBottom:'10px'}}>
                                            <span style={{fontSize:'14px', color: 'brown',fontWeight: 'bold'}}><u style={{    textUnderlineOffset: '3px'}}>PERSONAL DETAIL</u></span>
                                          </div>

                                          <div style={{width:'100%'}}>
                                            <table style={{fontSize:'9px'}}>
                                                <tr>
                                                    <th style={{width:'37%',verticalAlign: 'top'}}>Phone No</th>
                                                    <th style={{width:'5%',verticalAlign: 'top'}}>:</th>
                                                    <th style={{width:'58%',verticalAlign: 'top'}}>{val.phone}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{width:'37%',verticalAlign: 'top'}}>Emergency No</th>
                                                    <th style={{width:'5%',verticalAlign: 'top'}}>:</th>
                                                    <th style={{width:'58%',verticalAlign: 'top'}}>{val.emergency_contact_number}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{width:'37%',verticalAlign: 'top'}}>Email I.D</th>
                                                    <th style={{width:'5%',verticalAlign: 'top'}}>:</th>
                                                    <th style={{width:'58%',wordBreak: 'break-all'}}>{val.email}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{width:'37%',verticalAlign: 'top'}}>Blood Group</th>
                                                    <th style={{width:'5%',verticalAlign: 'top'}}>:</th>
                                                    <th style={{width:'58%',verticalAlign: 'top'}}>{val.blood_group}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{width:'37%',verticalAlign: 'top'}}>Address</th>
                                                    <th style={{width:'5%',verticalAlign: 'top'}}>:</th>
                                                    <th style={{width:'58%',verticalAlign: 'top',wordBreak: 'break-all',height: '50px'}}>
                                                        {val.present_address_one}
                                                    </th>
                                                </tr>
                                            </table>
                                          </div>

                                          <div style={{width:'100%'}}>
                                            <hr style={{margin:'0px',backgroundColor:'#0c6bbf'}}/>
                                            <div 
                                                    style={{
                                                        fontSize:'11px',
                                                        fontFamily: 'serif',
                                                        textAlign: 'center',
                                                        fontWeight: 'bolder',
                                                        color:'#0c6bbf'
                                                        }}>
                                                    {companyData[0].company_name.toUpperCase()}
                                                </div>
                                          </div>

                                          <div style={{width:'100%',marginTop:'7px'}}>
                                          <div style={{
                                                backgroundColor: 'blue',
                                                padding:'0px 3px 5px 3px',

                                            }}>
                                                <div style={{color:'white',fontSize:'9px',textAlign:'center',fontWeight:'bold',paddingLeft: '10px',paddingRight: '10px'}}>{companyData[0].address_one}</div>
                                                <div style={{color:'white',fontSize:'9px',textAlign:'center',fontWeight:'bold',marginTop:'7px'}}>{companyData[0].address_two}</div>
                                                <div style={{color:'white',fontSize:'9px',textAlign:'center',fontWeight:'bold'}}>{companyData[0].phone}</div>
                                                <div style={{color:'white',fontSize:'8px',textAlign:'center',fontWeight:'bold'}}>{companyData[0].website}</div>
                                            </div>          
                                          </div>

                                        </div>

                                                
                                            </div>
                                             </>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                    </div>

        </>
    )
}

export default React.memo(IdCard_view2);