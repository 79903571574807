
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import '../pages/pages.css';
import '../pages/admin.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Axios from 'axios';


const Shift = () => {

  const alert = useAlert();
  let history = useHistory();
  const locusr = JSON.parse(localStorage.getItem("user"));
  let user_typ = Apiconnect.decrypt(locusr.emp_typ);

  const [info, setInfo] = useState({
    name: "",
    working_hour: "",
    in_time: "", out_time: "", out_time_compulsory: "0", late_in_limit: "", early_out_limit: "",  
    lunch_start_time: "", lunch_end_time: "", tea_start_time: "", tea_end_time: "", late_in_time_tomark_halfday: "",
    early_out_time_tomark_halfday: "", timedelay_limit_for_halfday: "", timedelay_limit_for_absent: "", timedelay_deduction_from: "casula leave",
    company_id: Apiconnect.get_company_id(),
    id: null,
  });
  const { name, working_hour, in_time, out_time, out_time_compulsory, late_in_limit, early_out_limit, lunch_start_time, lunch_end_time, tea_start_time, tea_end_time, late_in_time_tomark_halfday, early_out_time_tomark_halfday, timedelay_limit_for_halfday, timedelay_limit_for_absent, timedelay_deduction_from, company_id, id } = info;
  const onInputChange = e => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };

  const [is_lunch, setIs_lunch] = useState(0);
  const [is_lunch_exclude_wh, setIs_lunch_exclude_wh] = useState(0);
  const [is_tea, setIs_tea] = useState(0);
  const [is_tea_exclude_wh, setIs_tea_exclude_wh] = useState(0);

  const onInputChange2 = e => {

    if(e.target.name === 'is_lunch'){
      if(e.target.checked === true){
        setIs_lunch(1);
      }else{
        setIs_lunch(0);
      }
    }

    if(e.target.name === 'is_lunch_exclude_wh'){
      if(e.target.checked === true){
        setIs_lunch_exclude_wh(1);
      }else{
        setIs_lunch_exclude_wh(0);
      }
    }

    if(e.target.name === 'is_tea'){
      if(e.target.checked === true){
        setIs_tea(1);
      }else{
        setIs_tea(0);
      }
    }

    if(e.target.name === 'is_tea_exclude_wh'){
      if(e.target.checked === true){
        setIs_tea_exclude_wh(1);
      }else{
        setIs_tea_exclude_wh(0);
      }
    }



  }

  const onSubmit = async e => {
    e.preventDefault();

      info.is_lunch = is_lunch;
      info.is_lunch_exclude_wh = is_lunch_exclude_wh;
      info.is_tea = is_tea;
      info.is_tea_exclude_wh = is_tea_exclude_wh;
      info.company_id = Apiconnect.get_company_id();

       console.log(info);
      // return false;
    if (id > 0) {
      console.log('in edit function' + id);

      
      let info_enc =  Apiconnect.encrypt_obj(info);
         const inf_a = { enc: info_enc };
      Apiconnect.postData(`shift/update/`, inf_a).then((response) => {
        history.push("/shift");
        setInfo({
          name: "",
          working_hour: "",
          in_time: "", out_time: "", out_time_compulsory: "0", late_in_limit: "", early_out_limit: "", 
          lunch_start_time: "", lunch_end_time: "", tea_start_time: "", tea_end_time: "", late_in_time_tomark_halfday: "",
          early_out_time_tomark_halfday: "", timedelay_limit_for_halfday: "", timedelay_limit_for_absent: "", timedelay_deduction_from: "casula leave",
          id: ""
        });
        setIs_lunch(0);
        setIs_lunch_exclude_wh(0);
        setIs_tea(0);
        setIs_tea_exclude_wh(0);
        getList();
        alert.success('Shift updated successfully.');
      });
    } else {
      console.log('in add function');
      let info_enc =  Apiconnect.encrypt_obj(info);
      const inf_a = { enc: info_enc };

      // let info_enc =  Apiconnect.encrypt_obj(info);
      //    const inf_a = { enc: info_enc };
      await Apiconnect.postData("shift/create",inf_a).then((response) => {

        setInfo({
          name: "",
          working_hour: "",
          in_time: "", out_time: "", out_time_compulsory: "0", late_in_limit: "", early_out_limit: "",
          lunch_start_time: "", lunch_end_time: "", tea_start_time: "", tea_end_time: "", late_in_time_tomark_halfday: "",
          early_out_time_tomark_halfday: "", timedelay_limit_for_halfday: "", timedelay_limit_for_absent: "", timedelay_deduction_from: "casula leave",
          id: ""
        });

        setIs_lunch(0);
        setIs_lunch_exclude_wh(0);
        setIs_tea(0);
        setIs_tea_exclude_wh(0);
        getList();

        alert.success('Shift added successfully.');

        
       });
    }

  };

  const ondlt = (id) => {
    confirmAlert({
      title: 'Are you sure to Delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteItem(id), 
        },
        {
          label: 'No',
        }
      ]
    });
  };

  const deleteItem = (id) => {
    Apiconnect.deleteData(`shift/delete/${id}`).then((response) => {
      history.push("/shift");
      getList();
    });
  };

  const getItem = (id) => {
    Apiconnect.getData(`shift/get/${id}`).then((response) => {
      console.log(response.data.data[0]);
      let _xtract = Apiconnect.decrypt_obj(response.data.data); 
      setInfo({
        name: _xtract[0].name, working_hour: _xtract[0].working_hour, in_time: _xtract[0].in_time, out_time: _xtract[0].out_time,
        out_time_compulsory: _xtract[0].out_time_compulsory, late_in_limit: _xtract[0].late_in_limit, early_out_limit: _xtract[0].early_out_limit,
         lunch_start_time: _xtract[0].lunch_start_time,
        lunch_end_time: _xtract[0].lunch_end_time,
        tea_start_time: _xtract[0].tea_start_time, tea_end_time: _xtract[0].tea_end_time,
        late_in_time_tomark_halfday: _xtract[0].late_in_time_tomark_halfday, early_out_time_tomark_halfday: _xtract[0].early_out_time_tomark_halfday,
        timedelay_limit_for_halfday: _xtract[0].timedelay_limit_for_halfday, timedelay_limit_for_absent: _xtract[0].timedelay_limit_for_absent,
        timedelay_deduction_from: _xtract[0].timedelay_deduction_from,id: _xtract[0].id
      });
      setIs_lunch(_xtract[0].is_lunch);
      setIs_lunch_exclude_wh(_xtract[0].is_lunch_exclude_wh);
      setIs_tea_exclude_wh(_xtract[0].is_tea_exclude_wh);
      setIs_tea(_xtract[0].is_tea);
    });
  };


  function refreshPage() {
    window.location.reload();
  }


  useEffect(() => { getList();leaveType(); }, []);
  const [List, setList] = useState([]);
  const getList = () => {
    Apiconnect.getData(`shift/getall/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data); 
      setList(_xtract.reverse());
    });
  };

      let Is_Lunch_Apl;
      if(is_lunch === 1) {
        Is_Lunch_Apl = true;
        var ck1 = 1;
      } else {
        Is_Lunch_Apl = '';
        var ck1 = 0;
      }
     // console.log(Is_Lunch_Apl);
     // console.log(is_lunch);

      let working_hours;
      if(is_lunch_exclude_wh === 1) {
        working_hours = true;
      } else {
        working_hours = '';
      }

      let is_tea_apl;
      if(is_tea === 1) {
        is_tea_apl = true;
      } else {
        is_tea_apl = '';
      }

      let tea_exclude_wh;
      if(is_tea_exclude_wh === 1) {
        tea_exclude_wh = true;
      } else {
        tea_exclude_wh = '';
      }


      const [viewList,setViewList] = useState({
        name1: "",
        working_hour1: "",
        in_time1: "", out_time1: "", out_time_compulsory1: "0", late_in_limit1: "", early_out_limit1: "", is_lunch1: "1", is_lunch_exclude_wh1: "1",
        lunch_start_time1: "", lunch_end_time1: "", is_tea1: "1", is_tea_exclude_wh1: "1", tea_start_time1: "", tea_end_time1: "", late_in_time_tomark_halfday1: "",
        early_out_time_tomark_halfday1: "", timedelay_limit_for_halfday1: "", timedelay_limit_for_absent1: "", timedelay_deduction_from1: "casula leave",
     });
  
     const { name1, working_hour1, in_time1, out_time1, out_time_compulsory1, late_in_limit1, early_out_limit1, is_lunch1, is_lunch_exclude_wh1,
       lunch_start_time1, lunch_end_time1, is_tea1, is_tea_exclude_wh1, tea_start_time1, tea_end_time1, late_in_time_tomark_halfday1, early_out_time_tomark_halfday1,
       timedelay_limit_for_halfday1, timedelay_limit_for_absent1, timedelay_deduction_from1 } = viewList;
  
     const viewItem = (id)=>{
        setModalIsOpen(true);
  
        Apiconnect.getData(`shift/get/${id}`).then((response) => {
           console.log(response.data.data[0]);
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           setViewList({
            name1: _xtract[0].name, working_hour1: _xtract[0].working_hour, in_time1: _xtract[0].in_time, out_time1: _xtract[0].out_time,
            out_time_compulsory1: _xtract[0].out_time_compulsory, late_in_limit1: _xtract[0].late_in_limit, early_out_limit1: _xtract[0].early_out_limit,
            is_lunch1: _xtract[0].is_lunch, is_lunch_exclude_wh1: _xtract[0].is_lunch_exclude_wh, lunch_start_time1: _xtract[0].lunch_start_time,
            lunch_end_time1: _xtract[0].lunch_end_time, is_tea1: _xtract[0].is_tea, is_tea_exclude_wh1: _xtract[0].is_tea_exclude_wh,
            tea_start_time1: _xtract[0].tea_start_time, tea_end_time1: _xtract[0].tea_end_time,
            late_in_time_tomark_halfday1: _xtract[0].late_in_time_tomark_halfday, early_out_time_tomark_halfday1: _xtract[0].early_out_time_tomark_halfday,
            timedelay_limit_for_halfday1: _xtract[0].timedelay_limit_for_halfday, timedelay_limit_for_absent1: _xtract[0].timedelay_limit_for_absent,
            timedelay_deduction_from1: _xtract[0].timedelay_deduction_from });
        });
     }
  
     const customStyles = {
        content: {
           top: '55%', width: '55%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
          
        },
     };
     const [modalIsOpen, setModalIsOpen] = useState(false);

      let timedelay_deduction = 'Comp Off';
     if(timedelay_deduction_from1 == 'casual_leave'){
      let timedelay_deduction = 'Casual Leave';
     }

     const [typeList,setleaveType]= useState([]);
     const leaveType = ()=>{
      Apiconnect.getData(`company_leave_policy/getall/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setleaveType(_xtract.reverse());
      });
   }
       

  return (
    <>

      <div id="content-page" className="content-page">
        <div className="container-fluid">


               <div >
                  <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Shift View</h4>
                           </div>
                        </div>

                        <div className="iq-card-body">
                           <div className="row">
                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Shift Name  : </b></span>
                                    <span>{name1} </span>
                              </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Working Hours : </b></span>
                                    <span>{working_hour1}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Out Time Compulsory : </b></span>
                                    <span>{out_time_compulsory1}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>In Time : </b></span>
                                    <span>{in_time1}</span>
                                 </div>
                              </div>
                              </div>
                              <div className="row">

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Out Time : </b></span>
                                    <span>{out_time1}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Late In Time : </b></span>
                                    <span>{late_in_limit1}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>early Out Time: </b></span>
                                    <span>{early_out_limit1}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Lunch Start Time : </b></span>
                                    <span>{lunch_start_time1}</span>
                                 </div>
                              </div>
                              </div>
                              <div className="row">

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Lunch End Time : </b></span>
                                    <span>{lunch_end_time1}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Tea Start Time : </b></span>
                                    <span>{tea_start_time1}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Tea End Time : </b></span>
                                    <span>{tea_end_time1}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Late In Time To Consider Half Day : </b></span>
                                    <span>{late_in_time_tomark_halfday1}</span>
                                 </div>
                              </div>
                              </div>
                              <div className="row">

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Early In Time To Consider Half Day : </b></span>
                                    <span>{early_out_time_tomark_halfday1}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Timedelay Deduction From : </b></span>
                                    <span>{timedelay_deduction}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Late In Time To Consider Half Day : </b></span>
                                    <span>{timedelay_limit_for_halfday1}</span>
                                 </div>
                              </div>

                              <div className="col-md-3">
                              <div className="form-group">
                                    <span><b>Early Out Time To Consider Half Day : </b></span>
                                    <span>{timedelay_limit_for_absent1}</span>
                                 </div>
                              </div>


                             
                           </div>
                        </div>
                        <div className="text_center">
                        <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div>



          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Company Shift</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <form onSubmit={e => onSubmit(e)}>
                    <div className="row">

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Shift Name <strong className="required"> *</strong></label>
                          <input type="text" className="form-control validate[required]" name="name" placeholder="Name"
                            value={name}
                            onChange={e => onInputChange(e)}
                            required />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <label>Working Hours</label>
                        <input type="number" className="form-control validate[required]" name="working_hour" value={working_hour}
                           onChange={e => onInputChange(e)}
                            placeholder="Working Hours" max="16" />
                      </div>

                      <div className="col-md-3">
                        <label>Is Out Time Compulsory</label>
                        <select className="form-control" name="out_time_compulsory"  value={out_time_compulsory}
                          onChange={e => onInputChange(e)}>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label>In Time</label>
                          <input type="time" className="form-control validate[required]" name="in_time" 
                          value={in_time}
                          onChange={e => onInputChange(e)}
                           />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Out Time</label>
                          <input type="time" className="form-control validate[required]" name="out_time" 
                          value={out_time}
                          onChange={e => onInputChange(e)}
                          />
                        </div>
                      </div>


                    
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Limit  for late incoming</label>
                          <input type="number" className="form-control validate[required]" name="late_in_limit" 
                           value={late_in_limit} placeholder="in minutes"
                           onChange={e => onInputChange(e)} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Limit  for early outgoing</label>
                          <input type="number" className="form-control validate[required]" name="early_out_limit"
                           value={early_out_limit} placeholder="in minutes"
                           onChange={e => onInputChange(e)} />
                        </div>
                      </div>


                      <div className="col-md-12 col-sm-12 col-lg-12 pt_25" style={{display: 'inline-flex'}}>

                        <div className="col-md-5 bbox"   >

                          <div className="row p_l_35" >
                          <label className="form-check-label" for="is_lunch">
                            <input className="form-check-input" type="checkbox" name="is_lunch" id="is_lunch" aria-label="..."
                             value={is_lunch} checked={Is_Lunch_Apl} data-id={ck1}
                             onChange={e => onInputChange2(e)}  />
                              Is Lunch Break Allowed?
                            </label>  
                          </div>



                          <div className="row p_l_35">
                          <label className="form-check-label" for="is_lunch_exclude_wh">
                            <input className="form-check-input" type="checkbox" name="is_lunch_exclude_wh" id="is_lunch_exclude_wh"aria-label="..." 
                             value={is_lunch_exclude_wh}
                             onChange={e => onInputChange2(e)} checked={working_hours} />
                              Exclude from working hours
                            </label>
                          </div>
                          <div className="row p_l_t_35" >
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Lunch Start Time</label>
                                <input type="time" className="form-control validate[required]" name="lunch_start_time"
                                value={lunch_start_time}
                                onChange={e => onInputChange(e)} />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Lunch End Time</label>
                                <input type="time" className="form-control validate[required]" name="lunch_end_time" 
                                 value={lunch_end_time}
                                 onChange={e => onInputChange(e)} />
                              </div>
                            </div>
                          </div>




                        </div>

                        <div className="col-md-2"   ></div>
                        <div className="col-md-5 bbox"   >

                          <div className="row p_l_35">
                          <label className="form-check-label" for="is_tea">
                            <input className="form-check-input" type="checkbox" name="is_tea" id="is_tea" aria-label="..." 
                             value={is_tea}
                             onChange={e => onInputChange2(e)} checked={is_tea_apl} />
                              Is Tea Break Allowed?
                            </label>
                          </div>
                          <div className="row p_l_35">
                          <label className="form-check-label" for="is_tea_exclude">
                            <input className="form-check-input" type="checkbox" name="is_tea_exclude_wh" id="is_tea_exclude"  aria-label="..."
                             value={is_tea_exclude_wh}
                             onChange={e => onInputChange2(e)} checked={tea_exclude_wh} />
                               Exclude from working hours
                            </label>
                          </div>
                          <div className="row p_l_t_35" >
                            <div className="col-md-3">
                              <label>Tea Start Time</label>
                              <input type="time" className="form-control validate[required]" name="tea_start_time" 
                               value={tea_start_time}
                               onChange={e => onInputChange(e)}/>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Tea End Time</label>
                                <input type="time" className="form-control validate[required]" name="tea_end_time" 
                                value={tea_end_time}
                                onChange={e => onInputChange(e)}/>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>


                      <div className="col-md-12 pt_25" style={{display: 'inline-flex'}}>
                        <div className="col-md-6" >
                          <div className="row">
                            <div className="col-md-6">
                              <label>Late In Time To Consider Half Day</label>
                              <input type="time" className="form-control validate[required]" name="late_in_time_tomark_halfday" 
                              value={late_in_time_tomark_halfday}
                              onChange={e => onInputChange(e)} />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Early Out Time To Consider Half Day</label>
                                <input type="time" className="form-control validate[required]" name="early_out_time_tomark_halfday" 
                                value={early_out_time_tomark_halfday}
                                onChange={e => onInputChange(e)} />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <label>Time Delay to Consider Half Day</label>
                              <input type="number" className="form-control validate[required]" name="timedelay_limit_for_halfday"
                                value={timedelay_limit_for_halfday} placeholder="In Minutes"
                                onChange={e => onInputChange(e)} />
                            </div>
                            <div className="col-md-6">                              
                                <label>Time Delay to Consider Absent</label>
                                <input type="number" className="form-control validate[required]" name="timedelay_limit_for_absent" 
                                  value={timedelay_limit_for_absent} placeholder="In Minutes"
                                  onChange={e => onInputChange(e)}  />                              
                            </div>
                          
                          </div>



                        </div>
                        

                        



                        <div className="col-md-6">
                          <div className="row ptb">
                            <label>Timedelay Deduction From</label>
                            <select className="form-control" name="timedelay_deduction_from"  value={timedelay_deduction_from}
                                onChange={e => onInputChange(e)}>
                                    <option value="">Select Type</option>
                              {typeList.map((val, key) => {       
                                                return (
                                                    <option key={key} value={val.id}>{val.name}</option>
                                                );
                                                })}
                            </select>
                          </div>
                           

                        </div>
                      </div>

                      <div className="col-md-12 mt-4">
                        <button type="submit" name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                        {(() => {
                          if (id > 0) {
                            return (
                              <button type="button" className="btn" onClick={refreshPage}> Return </button>
                            )
                          }
                        })()}
                      </div>
                    </div>

                  </form>

                

                </div>
              </div>
            </div>

            
            <div className="col-sm-12 col-lg-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">List Of Shift</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <table className="table table-striped table-hover">
                    <thead className="">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Working Hours</th>
                        <th scope="col">In Time</th>
                        <th scope="col">Out Time</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {List.map((val, key) => {
                        return (
                          <tr key={key}>
                            <th scope="row">{key + 1}</th>
                            <td>{val.name}</td>
                            <td>{val.working_hour}</td>
                            <td>{val.in_time}</td>
                            <td>{val.out_time}</td>
                            <td>

                            {
                              user_typ === '3' 
                              ? (
                                <button onClick={() => {ondlt(val.id);}}
                                className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>
                              ) 
                              : null
                            }

                              {/* <button onClick={() => { deleteItem(val.id); }}
                                className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button> */}
                               

                              <button onClick={() => { getItem(val.id); }}
                                className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button>

                              <button onClick={() => viewItem(val.id)}
                              className="btn btn-info mr-2"><i className="fa fa-eye" aria-hidden="true"></i></button>

                            </td>
                          </tr>
                        );
                      })}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

     
          </div>
        </div>
      </div>




    </>
  )
}

export default Shift