import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import "./ems_pages.css";
import "./userstyle.css";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Yearly_holidays() {

    useEffect(() => { getHolidayList();},[]);
    const [holidayList, setHolidayList] = useState([]);
    const getHolidayList = () => {
        Apiconnect.getData(`company_holiday/getDataYearly/${Apiconnect.get_company_id()}`).then((response) => {
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           setHolidayList(_xtract);
        });
  
     }
    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                <div className="row ">
                  <div className="col-lg-6">
                     <div className="iq-card">
                        {/* <img src="../images/small/holiday.jpg" className="img-fluid w-100 rounded" alt="#" /> */}
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Yearly Holidays</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <ul class="media-story m-0 p-0">
                              {holidayList.map((val, key) => {
                                 return (

                                    <li key={key} class="d-flex mb-4 align-items-center active">
                                       <img src="../images/page-img/s1.jpg" alt="story-img" class="rounded-circle img-fluid" />
                                       <div class="stories-data ml-3">
                                          <h5>{val.name}</h5>
                                          <p class="mb-0">{val.start_date}</p>
                                       </div>
                                    </li>


                                 );
                              })}
                           </ul>

                        </div>
                     </div>
                  </div>
                </div>
                </div>
            </div>
        </>
    )

}

export default Yearly_holidays